<form class="auth-container" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h5 class="w-100 mb-3">Insira o documento</h5>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>CPF/CNPJ</mat-label>
    <input type="text" matInput mask="CPF_CNPJ" formControlName="cgc" />
    <mat-error *ngIf="formGroup.get('cgc')?.hasError('required')">Este campo é obrigatório</mat-error>
    <mat-error *ngIf="formGroup.get('cgc')?.hasError('mask')">Digite um documento válido</mat-error>
  </mat-form-field>

  <div class="links-container">
    <span routerLink="/">Voltar</span>
  </div>

  <button mat-raised-button color="accent" type="submit">ENVIAR E-MAIL</button>
  <small class="mt-3">
    <b>AntiSpam:</b>
    Se você optou por um sistema AntiSpam para proteger sua caixa-postal de mensagens indesejadas, não esqueça de
    configurar seu provedor de e-mails para receber mensagens do domínio
    <b>@{{ enterpriseEmailDomain$ | async }}</b>
    . Caso não receba o e-mail, verifique a pasta de Lixo Eletrônico.
  </small>
</form>
