import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfigService } from 'src/app/core/services/config';
import { LoaderService } from 'src/app/core/services/loader';
import { CopartItem } from '../../models/CopartItem';
import { ExtratoCopartResponse } from '../../models/ExtratoCopartResponse';
import { CoparticipationService } from '../../services/coparticipation';

@Component({
  selector: 'app-lista-extrato-copart',
  templateUrl: './lista-extrato-copart.component.html',
  styleUrls: ['./lista-extrato-copart.component.scss'],
})
export class ListaExtratoCopartComponent implements OnInit {
  dataSource = new MatTableDataSource<CopartItem>();

  loaded = false;

  displayedColumns = this.configService.getConfiguration('COPART_COLUMNS');

  queryParams!: Params;

  extratoCopart!: ExtratoCopartResponse;

  constructor(
    private loader: LoaderService,
    private coparticipationService: CoparticipationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.loadQueryParams();
    this.loadCopart();
  }

  print(): void {
    print();
  }

  private loadCopart(): void {
    this.loader.show('Carregando...');

    this.coparticipationService
      .getCopart(this.queryParams)
      .subscribe(
        (response) => {
          this.extratoCopart = response;
          this.dataSource.data = response.coparticipation;
        },
        () => {
          this.router.navigate(['../'], {
            relativeTo: this.activatedRoute,
          });
        }
      )
      .add(() => {
        this.loaded = true;
        this.loader.hide();
      });
  }

  private loadQueryParams(): any {
    this.activatedRoute.queryParams.subscribe((p) => {
      this.queryParams = p;
    });
  }
}
