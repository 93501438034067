import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProtheusDatePipe } from './protheus-date';
import { DashToBarPipe } from './dash-to-bar';
import { ConfigLabelPipe } from './config-label';

@NgModule({
  declarations: [ProtheusDatePipe, DashToBarPipe, ConfigLabelPipe],
  imports: [CommonModule],
  exports: [ProtheusDatePipe, DashToBarPipe, ConfigLabelPipe],
})
export class PipesModule {}
