import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { BeneficiaryCardNumber } from 'src/app/core/models/BeneficiaryCardNumber';
import { AuthService } from 'src/app/core/services/auth';

@Component({
  selector: 'app-list-card-numbers',
  templateUrl: './list-card-numbers.component.html',
  styleUrls: ['./list-card-numbers.component.scss'],
})
export class ListCardNumbersComponent {
  dataSource = new MatTableDataSource<BeneficiaryCardNumber>();

  displayedColumns = ['typeUser', 'cpf', 'name', 'birthday', 'cardnum'];

  constructor(private router: Router, private authService: AuthService) {
    this.loadCardNumbers();
  }

  private loadCardNumbers(): void {
    const currentNavigation = this.router.getCurrentNavigation();
    const navigationState = currentNavigation?.extras.state;

    const cardNumbers = navigationState?.cardNumbers as BeneficiaryCardNumber[];

    if (!cardNumbers && !this.authService.isLogged) {
      this.router.navigate(['autenticacao']);
      return;
    }

    this.dataSource.data = cardNumbers;
  }
}
