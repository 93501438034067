import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-income-information',
  templateUrl: './income-information.component.html',
  styleUrls: ['./income-information.component.scss'],
})
export class IncomeInformationComponent implements OnInit {
  formGroup!: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
  }

  onSubmit(): void {
    const value = this.formGroup.getRawValue();
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      year: [null, Validators.required],
      cpf: [null, Validators.required],
    });
  }
}
