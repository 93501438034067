import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { BeneficiaryKinship } from '../../models/BeneficiaryKinship';

@Injectable()
export class KinshipService {
  constructor(private http: HttpClient) {}

  getAll(age: number, beneficiaryParentId?: string): Observable<BeneficiaryKinship[]> {
    const baseURL = endpoints.kinships;

    let params = new HttpParams().append('age', age);

    if (beneficiaryParentId) {
      params = params.append('bpid', beneficiaryParentId);
    }

    return this.http.get<BeneficiaryKinship[]>(baseURL, { params });
  }
}
