import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignInCredentials } from 'src/app/core/models/SignInCredentials';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { Store } from '@ngrx/store';
import { CoreActions, signInAttempt } from 'src/app/core/store/actions';
import { Actions, ofType } from '@ngrx/effects';
import { AuthService } from 'src/app/core/services/auth';
import { NotificationService } from 'src/app/core/services/notification';
import { filter, map } from 'rxjs/operators';
import { DefaultErrorResponse } from 'src/app/core/models/DefaultErrorResponse';
import { selectTenantIdWithoutBranch } from 'src/app/core/store/selectors';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/core/services/loader';
import { ConfigService } from 'src/app/core/services/config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  formGroup!: FormGroup;

  shouldShowInactivated = true;

  InactivatedEmailError$ = this.actions$.pipe(
    ofType(CoreActions.SignInError),
    filter(({ payload }: { payload: DefaultErrorResponse }) => payload?.message === 'Confirme o e-mail para acessar.'),
    map(Boolean)
  );

  email!: string;

  sentEmailConfirmation!: boolean;

  tenantId!: string;

  tenantIdSub!: Subscription;

  emailNonConfirmedText = this.config.getConfiguration('EMAIL_NON_CONFIRMED_TEXT');

  showResendEmailLink = this.config.getConfiguration('SHOW_RESEND_EMAIL_LINK');

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<IAppState>,
    private actions$: Actions,
    private authService: AuthService,
    private notification: NotificationService,
    private loader: LoaderService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.setTenantId();
  }

  ngOnDestroy(): void {
    this.tenantIdSub.unsubscribe();
  }

  onSubmit(): void {
    const signInCredentials = this.getSignInCredentials();

    this.store.dispatch(signInAttempt({ payload: signInCredentials }));
  }

  resendEmailConfirmation(): void {
    const emailToResend = this.formGroup.get('cgc')?.value;

    this.loader.show('Reenviando e-mail...');
    this.authService
      .resendEmailConfirmation(emailToResend)
      .subscribe((email) => {
        this.shouldShowInactivated = false;
        this.sentEmailConfirmation = true;
        this.notification.success('E-mail reenviado para ' + email);
      })
      .add(() => this.loader.hide());
  }

  private setTenantId(): void {
    this.tenantIdSub = this.store.select(selectTenantIdWithoutBranch).subscribe((tenantId) => {
      if (!tenantId) return;
      this.tenantId = tenantId;
    });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      cgc: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  private getSignInCredentials(): SignInCredentials {
    const value = this.formGroup.getRawValue();
    return new SignInCredentials({
      cgc: value.cgc,
      password: value.password,
      tenantId: this.tenantId,
    });
  }
}
