import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../../services/config';
import { loadEnterprise, toggleSidenav } from '../../store/actions';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectEnterprise, selectIsLogged, selectTenantNome } from '../../store/selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  enterprise$ = this.store.select(selectEnterprise);

  tenantNome$ = this.store.select(selectTenantNome);

  development = environment.development;

  isLogged$ = this.store.select(selectIsLogged);

  constructor(private store: Store<IAppState>, public configService: ConfigService) {}

  reloadEnterprise(): void {
    this.store.dispatch(loadEnterprise());
  }

  onToggleSidenav(): void {
    this.store.dispatch(toggleSidenav());
  }
}
