import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectIsLogged } from '../../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private store: Store<IAppState>) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectIsLogged).pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          return this.router.parseUrl('autenticacao');
        }

        return true;
      })
    );
  }
}
