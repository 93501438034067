import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../../services/config';

@Pipe({
  name: 'configLabel',
})
export class ConfigLabelPipe implements PipeTransform {
  constructor(private configService: ConfigService) {}

  transform(key: string): string {
    return this.configService.getLabel(key);
  }
}
