import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashToBar',
})
export class DashToBarPipe implements PipeTransform {
  transform(value: string): unknown {
    value = String(value ?? '');

    return value.replace(/-/g, '/');
  }
}
