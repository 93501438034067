export interface ConsentForm {
  version: string;
  termo: number;
  acceptanceDate: string;
  tenantId: string | undefined;
  tenantName: string | undefined;
  idClient: string | undefined;
  ip: string;
  cnpj?: string;
}

export enum TipoTermo {
  PoliticaSeguranca = 0,
  PoliticaPrivacidade = 1,
}
