import { safeParseISO } from 'src/app/core/utils/helpers';

export interface IInvoicesRequest {
  cgc: string;
  birthDate?: string;
}

export class InvoicesRequest {
  cgc: string;
  birthDate?: Date;

  constructor(data: IInvoicesRequest) {
    this.cgc = data.cgc;

    if (data.birthDate) {
      this.birthDate = safeParseISO(data.birthDate);
    }
  }
}
