<app-breadcrumb [steps]="[
    ['Início', 'inicio'],
    ['Selecionar Beneficiário', '']
  ]"></app-breadcrumb>

<div class="mt-4"></div>

<mat-form-field appearance="outline" *ngIf="config.getConfiguration('SHOW_TYPE_BENEFIT_FILTER')">
  <mat-label>Tipo do benefício</mat-label>
  <mat-select [formControl]="benefitTypeControl">
    <mat-option *ngFor="let benefitType of benefitTypes" [value]="benefitType">
      {{ benefitType.description }}
    </mat-option>
  </mat-select>
  <mat-error>Este campo é obrigatório</mat-error>
</mat-form-field>

<mat-form-field appearance="outline" class="ms-2">
  <mat-label>Pesquisar</mat-label>
  <input type="text" matInput [formControl]="pesquisaCtrl" />
</mat-form-field>

<mat-form-field appearance="outline" class="ms-2">
  <mat-label>Operadora</mat-label>
  <input type="text" matInput [formControl]="operadoraCtrl" />
</mat-form-field>

<mat-form-field appearance="outline" class="ms-2">
  <mat-label>Segmento</mat-label>
  <input type="text" matInput [formControl]="segmentoCtrl" />
</mat-form-field>

<mat-form-field appearance="outline" class="ms-2">
  <mat-label>Contrato</mat-label>
  <input type="text" matInput [formControl]="contratoCtrl" />
</mat-form-field>

<mat-form-field appearance="outline" class="ms-2">
  <mat-label>Subcontrato</mat-label>
  <input type="text" matInput [formControl]="subcontratoCtrl" />
</mat-form-field>

<mat-card>
  <div class="d-flex justify-content-between">
    <div>
      <h4>Beneficiários</h4>
      <p class="text-muted" *ngIf="config.getConfiguration('CAN_EDIT_BENEFICIARY')">
        Escolha o beneficiário para alteração ou escolha o titular para inclusão de dependente
      </p>
    </div>
    <div>
      <button mat-raised-button color="primary" routerLink="/dados-cadastrais/novo-titular"
        *ngIf="(userType$ | async) === 'PJ' && config.getConfiguration('CAN_ADD_BENEFICIARY')">
        Novo Titular
      </button>
      <button mat-raised-button collor="primary" (click)="exportToExcel()">
        Exportar Excel
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.name }}</td>
    </ng-container>

    <ng-container matColumnDef="birthDate">
      <th mat-header-cell *matHeaderCellDef>Data de Nasc.</th>
      <td mat-cell *matCellDef="let beneficiary">
        {{ beneficiary.birthDate | dashToBar }}
      </td>
    </ng-container>

    <ng-container matColumnDef="kinship">
      <th mat-header-cell *matHeaderCellDef>Parentesco</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.kinship }}</td>
    </ng-container>

    <ng-container matColumnDef="cardNumber">
      <th mat-header-cell *matHeaderCellDef>Carteirinha</th>
      <td mat-cell *matCellDef="let beneficiary">
        {{ beneficiary.cardNumber }}
      </td>
    </ng-container>

    <ng-container matColumnDef="contract">
      <th mat-header-cell *matHeaderCellDef>Contrato</th>
      <td mat-cell *matCellDef="let beneficiary">
        {{ beneficiary.contract }}
      </td>
    </ng-container>

    <ng-container matColumnDef="subcontract">
      <th mat-header-cell *matHeaderCellDef>Subcontrato</th>
      <td mat-cell *matCellDef="let beneficiary">
        {{ beneficiary.subcontract }}
      </td>
    </ng-container>

    <ng-container matColumnDef="operadora">
      <th mat-header-cell *matHeaderCellDef>Operadora</th>
      <td mat-cell *matCellDef="let beneficiary">
        {{ beneficiary.operadora }}
      </td>
    </ng-container>

    <ng-container matColumnDef="segmento">
      <th mat-header-cell *matHeaderCellDef>Segmento</th>
      <td mat-cell *matCellDef="let beneficiary">
        {{ beneficiary.segmento }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="text-end">Ações</th>
      <td mat-cell *matCellDef="let beneficiary" class="text-end">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <a mat-menu-item [routerLink]="getEditBeneficiaryRoute(beneficiary)"
            *ngIf="config.getConfiguration('CAN_EDIT_BENEFICIARY')">
            <div class="d-flex align-items-center">
              <fa-icon [icon]="faUserEdit"></fa-icon>
              <div class="ms-2">Alterar dados</div>
            </div>
          </a>
          <a mat-menu-item [routerLink]="getEditPlanRoute(beneficiary)"
            *ngIf="config.getConfiguration('CAN_EDIT_BENEFICIARY_PLAN')">
            <div class="d-flex align-items-center">
              <fa-icon [icon]="faIdCard"></fa-icon>
              <div class="ms-2">Alterar plano</div>
            </div>
          </a>
          <a mat-menu-item [routerLink]="'/dados-cadastrais/visualizar/' + beneficiary.idBeneficiary"
            [queryParams]="{ idClient: benefitTypeControl.value?.idClient }">
            <div class="d-flex align-items-center">
              <fa-icon [icon]="faEye"></fa-icon>
              <div class="ms-2">Visualizar dados</div>
            </div>
          </a>
          <a mat-menu-item routerLink="/dados-cadastrais/novo-dependente"
            [queryParams]="{ t: beneficiary.idBeneficiary }" [disabled]="!shouldShowAddDependentButton(beneficiary)"
            *ngIf="config.getConfiguration('CAN_ADD_DEPENDENT')">
            <div class="d-flex align-items-center">
              <fa-icon [icon]="faUserPlus"></fa-icon>
              <div class="ms-2">Adicionar Dependente</div>
            </div>
          </a>
          <a mat-menu-item [routerLink]="
              '/dados-cadastrais/bloquear-beneficiario/' + beneficiary.idBeneficiary + '/' + beneficiary.kinship
            " [queryParams]="{
              idClient: benefitTypeControl.value?.idClient
            }" *ngIf="config.getConfiguration('CAN_BLOCK_BENEFICIARY')">
            <div class="d-flex align-items-center">
              <fa-icon [icon]="faLock"></fa-icon>
              <div class="ms-2">Bloquear Beneficiario</div>
            </div>
          </a>
        </mat-menu>
      </td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length" class="text-center">Nenhum resultado encontrado
      </td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let beneficiary; columns: displayedColumns"></tr>
  </table>

  <mat-paginator #paginator [length]="dataSource.data.length" [pageSize]="20" formFieldAppearance="outline"
    [pageIndex]="1"
    [pageSizeOptions]="[20, 50, 100, 200, 500, dataSource.data.length > 500 ? dataSource.data.length : 1000]"></mat-paginator>
</mat-card>