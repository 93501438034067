import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserType } from '../../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  private readonly store = inject(Store);
  private readonly router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectUserType).pipe(
      take(1),
      map((userType) => (userType ? this.extractRoles(route).includes(userType) : this.router.parseUrl('inicio')))
    );
  }

  /**
   * Obtém a lista de cargos permitidos para
   * acessar a rota
   */
  private extractRoles(route: ActivatedRouteSnapshot): string[] {
    return (route.data.roles ?? []) as string[];
  }
}
