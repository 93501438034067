import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BeneficiaryPlan } from '../../../models/BeneficiaryPlan';

@Component({
  selector: 'app-beneficiary-plan-card',
  templateUrl: './beneficiary-plan-card.component.html',
  styleUrls: ['./beneficiary-plan-card.component.scss'],
})
export class BeneficiaryPlanCardComponent {
  @Input() plan!: BeneficiaryPlan;
  @Input() isSelected!: boolean;
  @Output() selectChanged = new EventEmitter<boolean>();

  constructor() {}

  onCheckBoxChange(): void {
    this.selectChanged.emit(true);
  }
}
