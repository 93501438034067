<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="mb-3">Dados da busca</h2>
      </div>

      <ng-container *ngIf="!formGroup.get('serviceDate')?.value">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Ano de cobrança</mat-label>
          <input type="text" matInput mask="0000" formControlName="billingYear" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Mês de cobrança (Opcional)</mat-label>
          <mat-select formControlName="billingMonth">
            <mat-option selected>Selecione</mat-option>
            <mat-option *ngFor="let month of months" [value]="month.value">{{ month.description }}</mat-option>
          </mat-select>
          <mat-error>Este campo é obrigatório</mat-error>
        </mat-form-field>
      </ng-container>

      <div class="col-12">
        <button mat-raised-button color="accent" type="submit">Buscar</button>
        <button mat-stroked-button color="secondary" class="ms-2" (click)="canceled.emit()" type="button">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</form>
