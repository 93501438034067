import { Injectable } from '@angular/core';
import { Enterprise } from '../../models/Enterprise';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor() {}

  setEnterpriseCssColors(enterprise: Enterprise): void {
    document.body.style.setProperty('--primaryColor', `#${enterprise.corPrimaria}`);
    document.body.style.setProperty('--secondaryColor', `#${enterprise.corSecundaria}`);
  }
}
