import { onlyNumbers } from 'src/app/core/utils/helpers';

export interface IFormModalCancelamentoImediato {
  account: string;
  accountDigit: string;
  accountType: string;
  agency: string;
  agencyDigit: string;
  bank: string;
  cgc: string;
  email: string;
  name: string;
  reasonCancellation: string;
  password: string;
}

export class FormModalCancelamentoImediato {
  account: string;
  accountDigit: string;
  accountType: string;
  agency: string;
  agencyDigit: string;
  bank: string;
  cgc: string;
  email: string;
  name: string;
  reasonCancellation: string;
  password: string;

  constructor(data: IFormModalCancelamentoImediato) {
    this.account = data.account;
    this.accountDigit = data.accountDigit;
    this.accountType = data.accountType;
    this.agency = data.agency;
    this.agencyDigit = data.agencyDigit;
    this.bank = data.bank;
    this.cgc = onlyNumbers(data.cgc);
    this.email = data.email;
    this.name = data.name;
    this.reasonCancellation = data.reasonCancellation;
    this.password = data.password;
  }
}
