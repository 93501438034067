import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectEnterprise, selectUser } from 'src/app/core/store/selectors';
import { getCurrentMonthName, getLastYears } from 'src/app/core/utils/helpers';
import { ExtratoCobrancaResponse } from '../../models/ExtratoCobrancaResponse';
import { PagamentosEDebitosService } from '../../services/pagamentos-e-debitos';

@Component({
  selector: 'app-declaracao-anual-de-debito',
  templateUrl: './declaracao-anual-de-debito.component.html',
  styleUrls: ['./declaracao-anual-de-debito.component.scss'],
})
export class DeclaracaoAnualDeDebitoComponent implements OnInit {
  lastFiveYears = getLastYears(5, {
    withCurrent: false,
  });

  formGroup!: FormGroup;

  extratoCobranca: ExtratoCobrancaResponse | null = null;

  currentDay = new Date().getDate();

  currentMonth = getCurrentMonthName();

  currentYear = new Date().getFullYear();

  user$ = this.store.select(selectUser);

  enterprise$ = this.store.select(selectEnterprise);

  constructor(
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    private pagamentosEDebitosService: PagamentosEDebitosService,
    private loader: LoaderService,
    private store: Store<IAppState>
  ) {}

  get selectedYear(): number {
    return this.formGroup.get('year')?.value;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Por favor, preencha o ano base.');
      return;
    }

    this.loadExtratoCobranca();
  }

  print(): void {
    print();
  }

  private loadExtratoCobranca(): void {
    this.loader.show('Buscando informações...');
    this.pagamentosEDebitosService
      .getAnnualStatement(this.selectedYear)
      .subscribe((extratoCobranca) => {
        this.extratoCobranca = extratoCobranca;
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      year: [null, Validators.required],
    });
  }
}
