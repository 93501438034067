import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SimpleInvoice } from 'src/app/core/models/SimpleInvoice';
import { InvoiceService } from 'src/app/core/services/invoice';
import { LoaderService } from 'src/app/core/services/loader';

@Component({
  selector: 'app-busca-extrato-cobranca',
  templateUrl: './busca-extrato-cobranca.component.html',
  styleUrls: ['./busca-extrato-cobranca.component.scss'],
})
export class BuscaExtratoCobrancaComponent implements OnInit {
  dataSource = new MatTableDataSource<SimpleInvoice>();

  displayedColumns = ['product', 'amount', 'dueDate', 'interestAmount', 'yearMonth'];

  constructor(private loader: LoaderService, private invoiceService: InvoiceService, private router: Router) {}

  ngOnInit(): void {
    this.loadInvoices();
  }

  onInvoiceSelected(invoice: SimpleInvoice): void {
    this.router.navigate([`extrato/cobranca/${invoice.idInvoice}/${invoice.idClient}`]);
  }

  private loadInvoices(): void {
    this.loader.show('Buscando boletos...');
    this.invoiceService
      .getAllInvoices(false)
      .subscribe((invoices) => {
        this.setInvoiceTable(invoices);
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private setInvoiceTable(invoices: SimpleInvoice[]): void {
    this.dataSource.data = invoices.sort((i, l) =>
      i.yearMonth < l.yearMonth ? 1 : i.yearMonth === l.yearMonth ? 0 : -1
    );
  }
}
