import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartaDeQuitacaoComponent } from './components/carta-de-quitacao';

import { DeclaracaoAnualDeDebitoComponent } from './components/declaracao-anual-de-debito';
import { InformeDePagamentoComponent } from './components/informe-de-pagamento';

const routes: Routes = [
  { path: 'declaracao-anual-de-debito', component: DeclaracaoAnualDeDebitoComponent },
  { path: 'carta-de-quitacao', component: CartaDeQuitacaoComponent },
  { path: 'informe-de-pagamento', component: InformeDePagamentoComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagamentosEDebitosRoutingModule {}
