import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/core/services/auth';
import { signOut } from 'src/app/core/store/actions';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';

@Component({
  selector: 'app-recovery-pass-token',
  templateUrl: './recovery-pass-token.component.html',
  styleUrls: ['./recovery-pass-token.component.scss'],
})
export class RecoveryPassTokenComponent implements OnInit {
  token!: string;
  loaded = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private store: Store<IAppState>
  ) {}

  ngOnInit(): void {
    this.signOut();
    this.setToken();
    this.validateToken();
  }

  private setToken(): void {
    this.activatedRoute.queryParams.subscribe(({ t }) => {
      this.token = t;
    });
  }

  private validateToken(): void {
    this.authService
      .validateRecoveryPassToken(this.token)
      .subscribe(() => {
        this.goToNewPass();
      })
      .add(() => {
        this.loaded = true;
      });
  }

  private goToNewPass(): void {
    this.router.navigate(['autenticacao', 'nova-senha'], {
      queryParams: {
        t: this.token,
      },
    });
  }

  private signOut(): void {
    this.store.dispatch(signOut());
  }
}
