<mat-card class="mt-4">
  <h4>Carteirinhas</h4>
  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="typeUser">
      <th mat-header-cell *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.typeUser }}</td>
    </ng-container>

    <ng-container matColumnDef="cpf">
      <th mat-header-cell *matHeaderCellDef>CPF</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.cpf | mask: 'CPF_CNPJ' }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.name }}</td>
    </ng-container>

    <ng-container matColumnDef="birthday">
      <th mat-header-cell *matHeaderCellDef>Data de Nasc.</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.birthday | protheusDate }}</td>
    </ng-container>

    <ng-container matColumnDef="cardnum">
      <th mat-header-cell *matHeaderCellDef>Número da Carteirinha</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.cardnum }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let invoice; columns: displayedColumns"></tr>
  </table>

  <button mat-raised-button color="accent" routerLink="/autenticacao" class="mt-3">Voltar</button>
</mat-card>
