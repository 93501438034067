import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import months from 'src/app/core/data/months';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectEnterprise, selectUser } from 'src/app/core/store/selectors';
import { getLastYears } from 'src/app/core/utils/helpers';
import { ExtratoCobrancaResponse } from '../../models/ExtratoCobrancaResponse';
import { PagamentosEDebitosService } from '../../services/pagamentos-e-debitos';

@Component({
  selector: 'app-carta-de-quitacao',
  templateUrl: './carta-de-quitacao.component.html',
  styleUrls: ['./carta-de-quitacao.component.scss'],
})
export class CartaDeQuitacaoComponent implements OnInit {
  lastFiveYears = getLastYears(5);
  months = months;

  formGroup!: FormGroup;

  cartaQuitacao!: ExtratoCobrancaResponse;

  user$ = this.store.select(selectUser);

  enterprise$ = this.store.select(selectEnterprise);

  constructor(
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    private pagamentosEDebitosService: PagamentosEDebitosService,
    private loader: LoaderService,
    private store: Store<IAppState>
  ) {}

  get selectedYear(): number {
    return this.formGroup.get('year')?.value;
  }

  get selectedMonth(): number {
    return this.formGroup.get('month')?.value;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Por favor, preencha o ano e o mês base.');
      return;
    }

    this.loadCartaQuitacao();
  }

  print(): void {
    print();
  }

  private loadCartaQuitacao(): void {
    this.loader.show('Buscando informações...');
    this.pagamentosEDebitosService
      .getDischardDebtLetter(this.selectedYear, this.selectedMonth)
      .subscribe((extratoCobranca) => {
        this.cartaQuitacao = extratoCobranca;
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      year: [null, Validators.required],
      month: [null, Validators.required],
    });
  }
}
