/**
 * Retorna a extensão do arquivo com
 * base no filename
 */
export const getExtensionFromFilename = (filename: string) => {
  const extension = filename.split('.').pop();

  if (!extension) {
    return '';
  }

  return extension.toLowerCase();
};
