<h4>Bem vindo ao {{ portalTitle$ | async }}!</h4>
<div class="container m-1">
  <div class="row">
    <div class="col-md-8">
      <div class="col-12">
        <div
          id="carouselExampleCaptions"
          class="carousel slide"
          [attr.data-bs-ride]="carouselRideMode"
          data-bs-interval="5000"
          data-bs-pause="hover"
          *ngIf="banners"
        >
          <div class="carousel-indicators" *ngIf="banners.length > 1">
            <button
              *ngFor="let banner of banners; let i = index; let first = first"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              [attr.data-bs-slide-to]="i"
              [class.active]="first"
              [attr.aria-label]="'Slide ' + i + 1"
            ></button>
          </div>

          <div class="carousel-inner">
            <div class="carousel-item" [class.active]="first" *ngFor="let banner of banners; let first = first">
              <a target="_blank" [href]="banner.linkTo">
                <img [src]="banner.imageUrl" class="d-block w-100 fit-cover" />
              </a>
            </div>
            <ng-container *ngIf="banners.length > 1">
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
              >
                <div class="icon-container">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </div>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
              >
                <div class="icon-container">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden" #next>Next</span>
                </div>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="cards">
        <div class="row">
          <div class="col-4 mt-3" *ngFor="let card of cards">
            <div class="card rounded-0 h-100">
              <img [src]="card.imageUrl" class="card-img-top rounded-0 card-image" height="110" [alt]="card.titulo" />
              <div class="card-body text-center p-0">
                <h5 class="card-title text-center mt-2 card-title-min-heigth">
                  <b>{{ card.titulo }}</b>
                </h5>
                <p class="card-text"></p>
                <div class="card-footer">
                  <a [attr.href]="card.linkTo" class="link-primary card-link" target="_blank">
                    {{ card.descricao }}
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="h-100" *ngIf="quickLinks && quickLinks.length > 0">
        <div class="card rounded-0 h-100" style="width: 18rem">
          <div class="mt-5 text-center quick-icon-size text-primary">
            <fa-icon [icon]="faChain"></fa-icon>
            <h5 class="card-title text-center text-black mt-1"><b>Quick links</b></h5>
          </div>

          <div class="card-body quicklink-container">
            <ul class="list-group list-group-flush w-100">
              <li class="list-group-item p-0 border-bottom-0 w-100" *ngFor="let quickLink of quickLinks">
                <div class="border-top p-2 me-2 ms-2">
                  <a [attr.href]="quickLink.linkTo" class="link-primary card-link" target="_blank">
                    {{ quickLink.descricao }}
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
