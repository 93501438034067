<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Beneficiários', 'dados-cadastrais/selecionar-beneficiario', { idClient: idClient }],
    ['Beneficiário ' + (beneficiary?.name ?? ''), '']
  ]"
></app-breadcrumb>

<ng-container *ngIf="beneficiary">
  <mat-card class="mt-4">
    <h4 class="my-4">1. Informações Gerais</h4>

    <table class="info-table">
      <tr>
        <td class="text-muted">Matrícula do Funcionário:</td>
        <td>{{ beneficiary.idEmployee || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Nome:</td>
        <td>{{ beneficiary.name || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Nome Social:</td>
        <td>{{ beneficiary.preferredName || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">CPF:</td>
        <td>{{ beneficiary.cpf || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">RG:</td>
        <td>{{ beneficiary.rg || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Orgão Emissor:</td>
        <td>{{ beneficiary.issuer || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Tipo:</td>
        <td>{{ beneficiary.type || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Parentesco:</td>
        <td>{{ beneficiary.kinship || '-' }}</td>
      </tr>
      <tr *ngIf="beneficiary.idHolder">
        <td class="text-muted">Ver Titular:</td>
        <td class="text-end text-primary text-decoration-underline">
          <a [routerLink]="'./' + beneficiary.idHolder">Ver titular</a>
        </td>
      </tr>
      <tr>
        <td class="text-muted">Nome da Mãe:</td>
        <td>{{ beneficiary.motherName || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Status:</td>
        <td>{{ beneficiary.status || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Estado Civil:</td>
        <td>{{ beneficiary.maritalStatus || '-' }}</td>
      </tr>
      <tr *ngIf="beneficiary.weddingDate">
        <td class="text-muted">Data de Casamento:</td>
        <td>{{ beneficiary.weddingDate || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Gênero:</td>
        <td>{{ beneficiary.gender || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Aniversário:</td>
        <td>{{ (beneficiary.birthDate | dashToBar) || '-' }}</td>
      </tr>
    </table>
    <h4 class="my-4">2. Informações de Contato</h4>

    <table class="info-table">
      <tr>
        <td class="text-muted">Endereço:</td>
        <td>{{ beneficiary.address || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Número:</td>
        <td>{{ beneficiary.addressNumber || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Complemento:</td>
        <td>{{ beneficiary.addressComplement || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Bairro:</td>
        <td>{{ beneficiary.district || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Cidade:</td>
        <td>{{ beneficiary.city || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">UF:</td>
        <td>{{ beneficiary.uf || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">CEP:</td>
        <td>{{ beneficiary.cep || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">E-mail:</td>
        <td>{{ beneficiary.email || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">DDD do celular:</td>
        <td>{{ beneficiary.dddCell || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Número do celular:</td>
        <td>{{ beneficiary.cellNumber || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">DDD do telefone:</td>
        <td>{{ beneficiary.dddHomePhone || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Número do telefone:</td>
        <td>{{ beneficiary.homePhone || '-' }}</td>
      </tr>
    </table>
    <h4 class="my-4">3. Informações do Produto</h4>

    <table class="info-table">
      <tr *ngIf="beneficiary.healthPlan.benefitType !== '23'">
        <td class="text-muted">Cartão:</td>
        <td>{{ beneficiary.cardNumber || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Nome do Produto:</td>
        <td>{{ beneficiary.healthPlan.plan || '-' }}</td>
      </tr>

      <ng-container *ngIf="beneficiary.healthPlan.benefitType === '01' || beneficiary.healthPlan.benefitType === '02'">
        <tr>
          <td class="text-muted">ANS:</td>
          <td>{{ beneficiary.healthPlan.ans || '-' }}</td>
        </tr>
        <tr>
          <td class="text-muted">Acomodação:</td>
          <td>{{ beneficiary.healthPlan.accommodation || '-' }}</td>
        </tr>
        <tr>
          <td class="text-muted">Coparticipação:</td>
          <td>{{ beneficiary.healthPlan.coparticipation ? 'SIM' : 'NÃO' || '-' }}</td>
        </tr>
        <tr>
          <td class="text-muted">Cobertura:</td>
          <td>{{ beneficiary.healthPlan.coverage || '-' }}</td>
        </tr>
      </ng-container>

      <tr>
        <td class="text-muted">Código do Provedor:</td>
        <td>{{ beneficiary.healthPlan.codeProvider || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Provedor:</td>
        <td>{{ beneficiary.healthPlan.provider || '-' }}</td>
      </tr>
      <tr>
        <td class="text-muted">Grupo/Empresa:</td>
        <td>{{ beneficiary.healthPlan.entity || '-' }}</td>
      </tr>
    </table>

    <div class="mt-3">
      <a
        mat-stroked-button
        color="secondary"
        class="ms-2"
        routerLink="/dados-cadastrais/selecionar-beneficiario"
        [queryParams]="{ idClient: idClient }"
      >
        Voltar
      </a>
    </div>
  </mat-card>
</ng-container>
