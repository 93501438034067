<div
  class="document-item"
  (click)="onContainerClick()"
  [class.required]="shouldShowRequiredBorder"
  [class.inserted]="shouldShowInsertedBorder"
  [class.c-pointer]="!shouldShowInsertedBorder"
>
  <div class="close-container" [class.d-none]="!hasValue" (click)="remove($event)">
    <mat-icon>close</mat-icon>
  </div>

  <div class="img-preview-container" [class.d-none]="!hasValue">
    <img class="img-fluid" #imgPreview [hidden]="!isImage" />
    <div>
      <div class="pdf-preview-container" #pdfPreviewContainer [hidden]="!isPdf">
        <canvas #pdfPreview></canvas>
      </div>
    </div>
    <div class="filename-container" *ngIf="!isImage && !isPdf">
      <p class="filename-extension">{{ fileExtension }}</p>
    </div>
  </div>
  <fa-icon class="img-icon" [icon]="faFileUpload" [class.d-none]="hasValue"></fa-icon>
  <div class="document-name">
    <div class="mt-2">
      {{ document.descricao }}
      <span class="text-danger" *ngIf="document.obrigatorio">*</span>
    </div>
  </div>
  <input type="file" class="d-none" #inputFile (change)="onInput($event)" />
</div>
