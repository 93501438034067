import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { ClientToUpdateClientMapper } from '../../mappers/ClientToUpdateClient';
import { Client } from '../../models/Client';
import { Contract } from '../../models/Contract';
import { UpdateClient } from '../../models/UpdateClient';

@Injectable()
export class ClientService {
  constructor(private http: HttpClient) {}

  getClient(): Observable<Client> {
    const baseURL = endpoints.client;
    const url = `${baseURL}/me`;

    return this.http.get<Client>(url);
  }

  updateClient(client: Client): Observable<UpdateClient> {
    const baseURL = endpoints.client;
    const url = `${baseURL}/me`;

    const body = ClientToUpdateClientMapper.convert(client);

    return this.http.put<UpdateClient>(url, body);
  }

  getContracts(): Observable<Contract[]> {
    const baseURL = endpoints.client;
    const url = `${baseURL}/contracts`;

    return this.http.get<Contract[]>(url);
  }
}
