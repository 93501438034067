export default {
  'Seguro Saúde': [
    {
      question: 'Tem desconto?',
      answer: 'Desconto apenas para dependentes. Os valores serão disponibilizados pelo RH.',
    },
    {
      question: 'Tem coparticipação? ',
      answer:
        'Sim, há coparticipação de 30% do valor dos procedimentos, sendo eles: Consultas Eletivas, Pronto Socorro e Exames Simples.',
    },
    {
      question: 'Existe carência?',
      answer: 'A inclusão  deve ocorrer até 30 dias após a admissão, para que entre sem carência.',
    },
    {
      question: 'Qual é o Plano de Saúde oferecido pela empresa?',
      answer: 'Bradesco Saúde. O plano varia de acordo com a elegibilidade do colaborador.',
    },
    {
      question: 'Posso incluir minha esposa(o) / filhos no plano?',
      answer:
        'Sim, a solicitação deve ser feita diretamente no RH, ou no ato da admissão. Para inclusão sem carência, deve ser solicitado até 30 dias da data do evento (Exemplos: Admissão, casamento, nascimento, adoção)',
    },
    {
      question: 'Como posso ter acesso a carteirinha digital?',
      answer: 'Disponível no App da Bradesco Saúde.',
    },
    {
      question: 'Como tenho acesso a rede credenciada / Atendimento?',
      answer: 'Disponível no App da Bradesco Saúde.',
    },
    {
      question: 'Tenho direito a reembolso?',
      answer:
        'Para análise do limite de reembolso é necessário solicitar a prévia, através do aplicativo  Bradesco Saúde.',
    },
    {
      question: 'Vou receber carteirinha fisica?',
      answer:
        'Não, as carteirinhas são apenas digitais, você pode consulta-la através do app ou site da Bradesco Saúde.',
    },
    {
      question: 'Fui demitido, serei excluido automaticamente do plano?',
      answer: 'Haverá permanencia até o ultimo dia do mês de demissão ou ultimo dia do aviso prévio.',
    },
    {
      question: 'Fui demitido, posso dar continuidade na Assistência médica?',
      answer:
        'Havera direito de permanência se no periodo contratual houver desconto de contribuição em folha referente a sua própria mensalidade. Lei 9656 art 30, 31.',
    },
  ],
  'Remover dependente no benefício.': [
    {
      question: 'Como excluir um dependente?',
      answer: `Comparecer no RH para preencher o formulário de exclusão.`,
    },
    {
      question: 'Quanto tempo para excluir?',
      answer: `O cancelamento acontece no último dia do mês solicitado`,
    },
  ],
  'Consulta ou Reclamação referentes a assistência médica.': [
    {
      question: 'Fui promovido de Rol, como faço para mudar meu plano?',
      answer:
        'O seu plano será alterado automaticamente no mês subsequente, desde que seja elegível.  Reforçando que, conforme politica de benefícios da empresa, a elegibilidade do plano de saúde está atrelado ao Rol e localidade de trabalho.',
    },
    {
      question: 'Posso solicitar upgrade de plano?',
      answer: 'Não há possibilidade de upgrade, os planos são de acordo com a localidade.',
    },
    {
      question: 'Como faço para receber a carteirinha do convênio?',
      answer: 'As operadoras não emitem mais carteirinha, você poderá visualiza-las através do APP.',
    },
    {
      question: 'Posso incluir agregados (pai, mãe, irmãos)?',
      answer: 'Não é permitido inclusão de agregados',
    },
    {
      question: 'Como tenho acesso a rede credenciada / Atendimento?',
      answer: 'Através do App de sua operadora',
    },
    {
      question: 'O plano cobre odonto?',
      answer: 'Não',
    },
  ],
};
