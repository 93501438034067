<form class="auth-container" [formGroup]="formGroup">
  <h5 class="w-100 mb-3">Selecione o ano base</h5>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Ano base</mat-label>
    <mat-select formControlName="year">
      <mat-option [value]="2020">2020</mat-option>
    </mat-select>
    <mat-error *ngIf="formGroup.get('year')?.hasError('required')">Este campo é obrigatório</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>CPF</mat-label>
    <input type="text" matInput formControlName="cpf" mask="000.000.000-00" />
    <mat-error *ngIf="formGroup.get('cpf')?.hasError('required')">Este campo é obrigatório</mat-error>
    <mat-error *ngIf="formGroup.get('cpf')?.hasError('mask')">Insira o CPF corretamente</mat-error>
  </mat-form-field>

  <button mat-raised-button color="accent" (click)="onSubmit()">VISUALIZAR</button>
</form>
