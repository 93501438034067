import { Config } from './config.interface';
import copartColumnsMds from './configurations/copart-columns-mds';
import faqBlanc from './configurations/faq-blanc';
import faqIndra from './configurations/faq-indra';

export const minsait = {
  menus: [
    'INICIO',

    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',

    'SESSAO_CHAMADOS',
    'LISTA_CHAMADOS',

    'SESSAO_EXTRATO',
    'SESSAO_IMPORTACAO',
    'EXTRATO_COPART',
    'SESSAO_FAQ',
  ],
  labels: {
    MENU_SESSAO_CADASTROS: 'Meus Benefícios',
    MENU_SESSAO_CHAMADOS: 'Solicitações',
    MENU_LISTA_CHAMADOS: 'Minhas Solicitações',

    HEADER_SITE_INSTITUCIONAL: 'De Bem Com a Vida',
    DADOS_CADASTRAIS: 'Dados Cadastrais',
  },
  otherHeaderLinks: {
    'RH CONECTA': 'https://rhconecta.indrabrasil.com.br/me/solicitacoes',
  },
  configurations: {
    PORTAL_PF_TITLE: 'Portal do Colaborador',
    PORTAL_PJ_TITLE: 'Portal do RH',
    AUTH_SHOULD_SHOW_SUBTITLE: false,
    AUTH_SHOULD_SHOW_BUTTONS: false,
    FIRST_ACCESS_CODE_FIELD: 'DATA_NASC',
    CAN_EDIT_BENEFICIARY: false,
    CAN_ADD_DEPENDENT: false,
    SHOW_TYPE_BENEFIT_FILTER: true,
    FAQ_LIST: faqIndra,
    COPART_COLUMNS: copartColumnsMds,
    PJ: {
      menus: ['INICIO', 'CONFIGURACOES', 'SESSAO_CHAMADOS', 'LISTA_CHAMADOS'],
    },
  },
} as Config;

export const blanchospital = {
  menus: [
    'INICIO',
    'CONFIGURACOES',
    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',

    'SESSAO_CHAMADOS',
    'LISTA_CHAMADOS',

    'SESSAO_EXTRATO',
    'SESSAO_IMPORTACAO',
    'EXTRATO_COPART',
    'SESSAO_FAQ',
  ],
  labels: {
    MENU_SESSAO_CADASTROS: 'Meus Benefícios',
    MENU_SESSAO_CHAMADOS: 'Solicitações',
    MENU_LISTA_CHAMADOS: 'Minhas Solicitações',

    HEADER_SITE_INSTITUCIONAL: 'De Bem Com a Vida',
    DADOS_CADASTRAIS: 'Dados Cadastrais',
  },
  otherHeaderLinks: {
    INTRANET: 'https://intranet.blanchospital.com.br/',
  },
  configurations: {
    PORTAL_PF_TITLE: 'Portal do Colaborador',
    PORTAL_PJ_TITLE: 'Portal do RH',
    AUTH_SHOULD_SHOW_SUBTITLE: false,
    AUTH_SHOULD_SHOW_BUTTONS: false,
    FIRST_ACCESS_CODE_FIELD: 'DATA_NASC',
    CAN_EDIT_BENEFICIARY: false,
    CAN_ADD_DEPENDENT: false,
    SHOW_TYPE_BENEFIT_FILTER: true,
    COPART_COLUMNS: copartColumnsMds,
    ENABLE_EMAIL_CREATE_ACCOUNT: true,
    FAQ_LIST: faqBlanc,
    EMAIL_NON_CONFIRMED_TEXT:
      'O e-mail de acesso não foi confirmado! Verifique sua caixa de e-mail, caso não encontre, faça o primeiro acesso novamente.',
    SHOW_RESEND_EMAIL_LINK: false,
  },
} as Config;

export const wtw = {
  menus: [
    'INICIO',

    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',

    'SESSAO_CHAMADOS',
    'LISTA_CHAMADOS',

    'SESSAO_EXTRATO',
    'SESSAO_IMPORTACAO',
    'EXTRATO_COPART',
    'SESSAO_FAQ',
  ],
  labels: {
    MENU_SESSAO_CADASTROS: 'Meus Benefícios',
    MENU_SESSAO_CHAMADOS: 'Solicitações',
    MENU_LISTA_CHAMADOS: 'Minhas Solicitações',

    DADOS_CADASTRAIS: 'Dados Cadastrais',
  },
  configurations: {
    PORTAL_PF_TITLE: 'Portal do Colaborador',
    PORTAL_PJ_TITLE: 'Portal do RH',
    AUTH_SHOULD_SHOW_SUBTITLE: false,
    AUTH_SHOULD_SHOW_BUTTONS: false,
    SHOW_TYPE_BENEFIT_FILTER: true,
    COPART_COLUMNS: copartColumnsMds,
    ENABLE_EMAIL_CREATE_ACCOUNT: true,
    FIRST_ACCESS_CODE_FIELD: 'DATA_NASC',
    CAN_EDIT_BENEFICIARY: true,
    FAQ_LIST: faqBlanc,
    EMAIL_NON_CONFIRMED_TEXT:
      'O e-mail de acesso não foi confirmado! Verifique sua caixa de e-mail, caso não encontre, faça o primeiro acesso novamente.',
    SHOW_RESEND_EMAIL_LINK: false,
    SHOULD_SEND_IBGE_CODE_ON_GET_PLANS: true,
    DATA_DE_VIGENCIA_EDITAVEL_PARA_RECEM_CASADO_RECEM_NASCIDO: true,
    SHOULD_SHOW_RULE_FIELD_ON_BENEFICIARY_FORM: true,
    BENEFICIARY_SHOW_CONTRACT: false,
    BENEFICIARY_SHOW_SUB_CONTRACT: true,
    SEGURANCA_INFORMACAO_ROUTE:
      'https://divitechblobportal.blob.core.windows.net/pdfcontainer/5407c4fe-d1c7-4bc7-9d81-a54a2f4868ae.pdf',
    PRIVACIDADE_ROUTE:
      'https://divitechblobportal.blob.core.windows.net/pdfcontainer/1ca487af-f902-4a53-bf70-bbea769c0f7f.pdf',
    SHOUD_PHONE_FOOTER_BE_WHITE: true,
    SHOUD_BANNER_CAROUSEL_DYNAMIC_ROTATE: true,
    SHOUD_ADD_BANNERS_TYPES: true,
    PJ: {
      menus: ['INICIO', 'CONFIGURACOES', 'SESSAO_CADASTROS', 'SESSAO_CHAMADOS', 'SESSAO_IMPORTACAO', 'LISTA_CHAMADOS'],
    },
    FORM_BENEFICIARIO_NOT_REQUIRED: {
      fields: ['rg', 'issuer', 'cellNumber', 'cep', 'address', 'addressNumber', 'district'],
    },
  },
} as Config;

export const wtwapresentacao = wtw;

export const demonstracao = {
  menus: [
    'INICIO',

    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',

    'SESSAO_CHAMADOS',
    'LISTA_CHAMADOS',

    'SESSAO_EXTRATO',
    'SESSAO_IMPORTACAO',
    'EXTRATO_COPART',
    'SESSAO_FAQ',
  ],
  labels: {
    MENU_SESSAO_CADASTROS: 'Meus Benefícios',
    MENU_SESSAO_CHAMADOS: 'Solicitações',
    MENU_LISTA_CHAMADOS: 'Minhas Solicitações',

    HEADER_SITE_INSTITUCIONAL: 'De Bem Com a Vida',
    DADOS_CADASTRAIS: 'Dados Cadastrais',
  },
  otherHeaderLinks: {
    'RH CONECTA': 'https://rhconecta.indrabrasil.com.br/me/solicitacoes',
  },
  configurations: {
    PORTAL_PF_TITLE: 'Portal do Colaborador',
    PORTAL_PJ_TITLE: 'Portal do RH',
    AUTH_SHOULD_SHOW_SUBTITLE: false,
    AUTH_SHOULD_SHOW_BUTTONS: false,
    FIRST_ACCESS_CODE_FIELD: 'DATA_NASC',
    CAN_EDIT_BENEFICIARY: false,
    CAN_ADD_DEPENDENT: false,
    SHOW_TYPE_BENEFIT_FILTER: true,
    FAQ_LIST: faqIndra,
    COPART_COLUMNS: copartColumnsMds,
    PJ: {
      menus: ['INICIO', 'CONFIGURACOES', 'SESSAO_CADASTROS', 'SESSAO_CHAMADOS', 'LISTA_CHAMADOS'],
    },
  },
} as Config;

export const uniben = {
  menus: [
    'INICIO',
    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',
    'CANCELAMENTO_BENEFICIÁRIO',

    'SESSAO_EXTRATO',
    'EXTRATO_COBRANCA',
    'EXTRATO_COPART',
    'SESSAO_PGTOS_E_DEBITOS',
    'DECL_ANUAL_DEB',
    'CARTA_QUITACAO',
    'INFORME_PGTOS',

    'SESSAO_SEGUNDA_VIA',
    'SEGUNDA_VIA_BOLETO',

    'SESSAO_DOWNLOADS',
    'GUIA_DO_CONSUMIDOR',
  ],
  configurations: {
    AUTH_SHOULD_SHOW_BUTTONS: false,
    CAN_EDIT_BENEFICIARY: false,
    SHOW_TYPE_BENEFIT_FILTER: true,
    CAN_ADD_DEPENDENT: false,
    PJ: {
      menus: [
        'INICIO',
        'SESSAO_CADASTROS',
        'LISTA_BENEFICIÁRIOS',
        'CANCELAMENTO_BENEFICIÁRIO',

        'SESSAO_EXTRATO',
        'EXTRATO_COBRANCA',
        'SESSAO_PGTOS_E_DEBITOS',
        'DECL_ANUAL_DEB',
        'CARTA_QUITACAO',
        'INFORME_PGTOS',

        'SESSAO_SEGUNDA_VIA',
        'SEGUNDA_VIA_BOLETO',

        'SESSAO_DOWNLOADS',
        'GUIA_DO_CONSUMIDOR',
      ],
      configurations: {
        CAN_BLOCK_BENEFICIARY: false,
        CAN_EDIT_BENEFICIARY_PLAN: false,
        CAN_ADD_BENEFICIARY: false,
      },
    },
  },
} as Config;

export const voceclube = {
  configurations: {
    CAN_EDIT_BENEFICIARY_CELLPHONE: false,
    CAN_EDIT_BENEFICIARY_EMAIL: false,
    SHOW_TYPE_BENEFIT_FILTER: true,
  },
} as Config;

export const barela = {
  menus: [
    'INICIO',

    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',

    'SESSAO_CHAMADOS',
    'LISTA_CHAMADOS',

    'SESSAO_EXTRATO',
    'SESSAO_IMPORTACAO',
    'EXTRATO_COPART',
    'SESSAO_FAQ',
  ],
  labels: {
    MENU_SESSAO_CADASTROS: 'Meus Benefícios',
    MENU_SESSAO_CHAMADOS: 'Solicitações',
    MENU_LISTA_CHAMADOS: 'Minhas Solicitações',

    DADOS_CADASTRAIS: 'Dados Cadastrais',
  },
  configurations: {
    PORTAL_PF_TITLE: 'Portal do Colaborador',
    PORTAL_PJ_TITLE: 'Portal do RH',
    AUTH_SHOULD_SHOW_SUBTITLE: false,
    AUTH_SHOULD_SHOW_BUTTONS: false,
    SHOW_TYPE_BENEFIT_FILTER: true,
    COPART_COLUMNS: copartColumnsMds,
    ENABLE_EMAIL_CREATE_ACCOUNT: true,
    FIRST_ACCESS_CODE_FIELD: 'DATA_NASC',
    CAN_EDIT_BENEFICIARY: true,
    FAQ_LIST: faqBlanc,
    EMAIL_NON_CONFIRMED_TEXT:
      'O e-mail de acesso não foi confirmado! Verifique sua caixa de e-mail, caso não encontre, faça o primeiro acesso novamente.',
    SHOW_RESEND_EMAIL_LINK: false,
    SHOULD_SEND_IBGE_CODE_ON_GET_PLANS: true,
    DATA_DE_VIGENCIA_EDITAVEL_PARA_RECEM_CASADO_RECEM_NASCIDO: true,
    SHOULD_SHOW_RULE_FIELD_ON_BENEFICIARY_FORM: true,
    BENEFICIARY_SHOW_CONTRACT: false,
    BENEFICIARY_SHOW_SUB_CONTRACT: true,
    PJ: {
      menus: ['INICIO', 'CONFIGURACOES', 'SESSAO_CADASTROS', 'SESSAO_CHAMADOS', 'LISTA_CHAMADOS'],
    },
    FORM_BENEFICIARIO_NOT_REQUIRED: {
      fields: ['rg', 'issuer', 'cellNumber', 'cep', 'address', 'addressNumber', 'district'],
    },
  },
} as Config;
