import { onlyNumbers } from 'src/app/core/utils/helpers';

export interface IFirstAccessRequest {
  webValidationNumber: string;
  document: string;
}

export class FirstAccessRequest {
  webValidationNumber: string;
  document: string;

  constructor(data: FirstAccessRequest) {
    this.webValidationNumber = data.webValidationNumber;
    this.document = onlyNumbers(data.document ?? '');
  }
}
