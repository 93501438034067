<div class="header-spacing"></div>
<div class="header-container">
  <div>
    <div>
      <img class="logo-img" [src]="(enterprise$ | async)?.logoUrl" />
    </div>
    <div>
      <img class="ans-img" [src]="(enterprise$ | async)?.ansImg" />
    </div>
  </div>

  <div class="toggle-menu-container" *ngIf="isLogged$ | async">
    <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div class="links-container">
    <div class="link-container">
      <span *ngIf="development" (click)="reloadEnterprise()">Recarregar</span>
      <div class="line"></div>
    </div>
    <div class="link-container">
      <a routerLink="/">Início</a>
      <div class="line"></div>
    </div>
    <div class="link-container">
      <a [href]="(enterprise$ | async)?.site" target="blank">
        {{ 'HEADER_SITE_INSTITUCIONAL' | configLabel }}
      </a>

      <div class="line"></div>
    </div>

    <div class="link-container" *ngFor="let item of configService.config.otherHeaderLinks | keyvalue">
      <a [href]="item.value" target="blank">{{ item.key }}</a>
      <div class="line"></div>
    </div>
  </div>
</div>
