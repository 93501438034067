<div class="health-statement-container" *ngIf="dpsQuestions">
  <div class="row">
    <form [formGroup]="formGroup" class="col-12" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Peso (Kg)</mat-label>
            <input matInput formControlName="weight" />
            <mat-error>Este campo é obrigatório</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Altura (cm)</mat-label>
            <input matInput formControlName="height" />
            <mat-error>Este campo é obrigatório</mat-error>
          </mat-form-field>
        </div>
      </div>

      <table class="table table-hover table-responsive">
        <thead>
          <tr>
            <th>Cód.</th>
            <th class="description-table-header">Descrição</th>
            <th>Resposta</th>
            <th>Observação</th>
            <th>Data do Evento</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let questionFG of questionsForm.controls; index as i" formArrayName="questions">
            <ng-container [formGroupName]="i">
              <td>{{ questionFG.value.code }}</td>
              <td>{{ questionFG.value.description }}</td>
              <td class="bg-white">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Resposta</mat-label>
                  <mat-select formControlName="answer">
                    <mat-option value="S">Sim</mat-option>
                    <mat-option value="N">Não</mat-option>
                  </mat-select>
                  <mat-error>Este campo é obrigatório</mat-error>
                </mat-form-field>
              </td>
              <td class="bg-white">
                <mat-form-field appearance="outline" class="w-100" *ngIf="isRowYes(i)">
                  <mat-label>Descreva.</mat-label>
                  <input matInput formControlName="observation" />
                  <mat-error>Este campo é obrigatório</mat-error>
                </mat-form-field>
              </td>
              <td class="bg-white">
                <mat-form-field appearance="outline" class="w-100" *ngIf="isRowYes(i)">
                  <mat-label>Data do evento</mat-label>
                  <input matInput formControlName="dataEvento" mask="00/00/0000" />
                  <mat-error>Este campo é obrigatório</mat-error>
                </mat-form-field>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
      <div class="mt-3">
        <button mat-stroked-button color="secondary" type="button" (click)="onBackClick()">Voltar</button>
        <button mat-raised-button color="accent" type="submit" class="ms-2">Continuar</button>
      </div>
    </form>
  </div>
</div>
