<div class="cpf-services-container">
  <form [formGroup]="formGroup" class="w-100">
    <h5 class="mb-3">O que você deseja buscar?</h5>

    <div class="mb-3">
      <mat-radio-group formControlName="resource">
        <mat-radio-button value="boleto" class="d-block">Boleto</mat-radio-button>
        <mat-radio-button value="ir" class="d-block">Informe de Pagamento (IR)</mat-radio-button>
        <mat-radio-button value="carteirinha" class="d-block">Carteirinha</mat-radio-button>
      </mat-radio-group>
    </div>

    <ng-container *ngIf="resource === 'boleto'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>CPF/CNPJ</mat-label>
        <input type="text" matInput formControlName="cgc" mask="CPF_CNPJ" />
        <mat-error *ngIf="formGroup.get('cgc')?.hasError('required')">Este campo é obrigatório</mat-error>
        <mat-error *ngIf="formGroup.get('cgc')?.hasError('mask')">Insira o documento corretamente</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100" *ngIf="shouldShowBirthDateControl">
        <mat-label>Data de Nascimento</mat-label>
        <input
          type="text"
          matInput
          formControlName="birthDate"
          mask="00/00/0000"
          placeholder="dd/mm/yyyy"
          [dropSpecialCharacters]="false"
        />
        <mat-error *ngIf="formGroup.get('birthDate')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="resource === 'carteirinha'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>CPF/CNPJ</mat-label>
        <input type="text" matInput formControlName="cgc" mask="CPF_CNPJ" />
        <mat-error *ngIf="formGroup.get('cgc')?.hasError('required')">Este campo é obrigatório</mat-error>
        <mat-error *ngIf="formGroup.get('cgc')?.hasError('mask')">Insira o documento corretamente</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100" *ngIf="shouldShowBirthDateControl">
        <mat-label>Data de Nascimento</mat-label>
        <input
          type="text"
          matInput
          formControlName="birthDate"
          mask="00/00/0000"
          placeholder="dd/mm/yyyy"
          [dropSpecialCharacters]="false"
        />
        <mat-error *ngIf="formGroup.get('birthDate')?.hasError('required')">Este campo é obrigatório</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="resource === 'ir'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>CPF/CNPJ</mat-label>
        <input type="text" matInput formControlName="cgc" mask="CPF_CNPJ" />
        <mat-error *ngIf="formGroup.get('cgc')?.hasError('required')">Este campo é obrigatório</mat-error>
        <mat-error *ngIf="formGroup.get('cgc')?.hasError('mask')">Insira o documento corretamente</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Ano Base</mat-label>
        <input type="text" matInput formControlName="baseYear" mask="0000" />
        <mat-error *ngIf="formGroup.get('baseYear')?.hasError('required')">Este campo é obrigatório</mat-error>
        <mat-error *ngIf="formGroup.get('baseYear')?.hasError('mask')">Insira o ano corretamente</mat-error>
      </mat-form-field>
    </ng-container>

    <div class="buttons-container">
      <button mat-raised-button color="accent" (click)="onSearchClick()">Buscar</button>
    </div>
  </form>
</div>
