import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { BeneficiaryDocument } from '../../models/BeneficiaryDocument';

@Injectable()
export class DocumentService {
  constructor(private http: HttpClient) {}

  getDocuments(beneficiaryType: string): Observable<BeneficiaryDocument[]> {
    const baseURL = endpoints.beneficiaries;
    const url = `${baseURL}/documents`;

    const params = {
      beneficiaryType,
    };

    return this.http.get<BeneficiaryDocument[]>(url, { params });
  }
}
