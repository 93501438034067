// Import the core angular services.
import { Injectable } from '@angular/core';

export interface IconsConfig {
  [name: string]: IconConfig;
}

export interface IconConfig {
  type: string;
  href: string;
  isDefault?: boolean;
}

export abstract class Favicons {
  abstract activate(href: string): void;
}

@Injectable()
export class BrowserFavicons implements Favicons {
  private elementId: string;

  constructor() {
    this.elementId = 'favicons-service-injected-node';
    this.removeExternalLinkElements();
  }

  public activate(href: string): void {
    this.addNode('image/png', href);
  }

  private addNode(type: string, href: string): void {
    var linkElement = document.createElement('link');
    linkElement.setAttribute('id', this.elementId);
    linkElement.setAttribute('rel', 'icon');
    linkElement.setAttribute('type', type);
    linkElement.setAttribute('href', href);
    document.head.appendChild(linkElement);
  }

  private removeExternalLinkElements(): void {
    var linkElements = document.querySelectorAll("link[ rel ~= 'icon' i]");

    for (var linkElement of Array.from(linkElements)) {
      linkElement?.parentNode?.removeChild(linkElement);
    }
  }

  private removeNode(): void {
    var linkElement = document.head.querySelector('#' + this.elementId);

    if (linkElement) {
      document.head.removeChild(linkElement);
    }
  }
}
