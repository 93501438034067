import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap, take } from 'rxjs';
import { IncomeReport } from 'src/app/modules/pagamentos-e-debitos/models/IncomeReport';
import { endpoints } from 'src/environments/endpoints';
import { PaymentReportContract } from '../../models/PaymentReportContract';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectTenantId } from '../../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class PaymentReportService {
  constructor(private http: HttpClient, private store: Store<IAppState>) {}

  getIncomeReport(baseYear: string): Observable<IncomeReport> {
    const baseURL = endpoints.incomeReport;
    const url = `${baseURL}/${baseYear}`;

    return this.http.get<IncomeReport>(url);
  }

  getIncomeReportByIdClientAndBaseYear(idClient: string, baseYear: string, branch: string): Observable<IncomeReport> {
    const baseURL = endpoints.incomeReport;
    const url = `${baseURL}/${baseYear}/${idClient}`;
    let headers = new HttpHeaders();

    return this.store.select(selectTenantId).pipe(
      take(1),
      switchMap((tenantId) => {
        headers = headers.set('tenantid', `${tenantId},${branch}`);
        return this.http.get<IncomeReport>(url, {
          headers,
        });
      })
    );
  }

  listContracts(cgc: string, baseYear: string): Observable<PaymentReportContract[]> {
    const baseURL = endpoints.incomeReport;
    const url = `${baseURL}/list-contracts`;

    const params = {
      cgc,
      baseYear,
    };

    return this.http.get<PaymentReportContract[]>(url, { params });
  }
}
