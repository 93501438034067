<mat-card [class.selected]="isSelected" [ngClass]="{ 'current-plan': plan.isCurrentPlan }">
  <div class="radio-container">
    <div class="mat-radio">
      <mat-radio-button
        (change)="onCheckBoxChange()"
        [checked]="isSelected"
        [disabled]="plan.isCurrentPlan"
      ></mat-radio-button>
    </div>
  </div>

  <h5 class="mb-0 text-center">{{ plan.description }}</h5>
  <p class="text-muted text-center">
    <small>
      {{ plan.shortDescription }}
    </small>
  </p>

  <p *ngIf="plan.isCurrentPlan" class="text-muted text-center">
    <strong>Plano Atual</strong>
  </p>

  <p class="mb-0">Código ANS</p>
  <p class="text-muted mb-2">
    <small>{{ plan.ansCode }}</small>
  </p>

  <p class="mb-0">Código</p>
  <p class="text-muted mb-2">
    <small>{{ plan.code }}</small>
  </p>

  <p class="mb-0">Abrangência</p>
  <p class="text-muted mb-2">
    <small>{{ plan.coverage }}</small>
  </p>

  <p class="mb-0">Acomodação</p>
  <p class="text-muted mb-2">
    <small>{{ plan.accommodation }}</small>
  </p>

  <p class="mb-0">Valor Plano</p>
  <p class="text-muted mb-2">
    <small>{{ plan.rangeValue | currency : 'BRL' }}</small>
  </p>
</mat-card>
