import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { endpoints } from 'src/environments/endpoints';

import { FirstAccessRequest } from 'src/app/modules/auth/models/FirstAccessRequest';
import { FirstAccessResponse } from 'src/app/modules/auth/models/FirstAccessResponse';
import { CreateUserRequest } from 'src/app/modules/auth/models/CreateUserRequest';
import { InvoicesRequest } from 'src/app/modules/auth/models/InvoicesRequest';
import { ListSimpleInvoice } from '../../models/ListSimpleInvoice';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  firstAccess(payload: FirstAccessRequest): Observable<FirstAccessResponse> {
    const baseURL = endpoints.users;
    const url = `${baseURL}/firstAccess`;

    return this.http.post<FirstAccessResponse>(url, payload);
  }

  createUser(payload: CreateUserRequest, tenantNome: string): Observable<any> {
    const url = endpoints.users;

    return this.http.post<any>(url, payload, {
      headers: {
        tenantId: `${payload.tenantId},${payload.branch}`,
        tenantNome,
      },
    });
  }

  getInvoices(payload: InvoicesRequest): Observable<ListSimpleInvoice> {
    const baseURL = endpoints.invoice;
    const url = `${baseURL}/list-by-cgc`;

    return this.http.post<ListSimpleInvoice>(url, payload);
  }

  getCardNumber(cpf: string): Observable<any> {
    const baseURL = endpoints.users;
    const url = `${baseURL}/${cpf}/getCardNumber`;

    return this.http.get(url);
  }
}
