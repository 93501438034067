import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth';
import { NotificationService } from 'src/app/core/services/notification';

@Component({
  selector: 'app-email-validation',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.scss'],
})
export class EmailValidationComponent implements OnInit {
  token!: string;

  loaded = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadParams();
    this.confirmEmail();
  }

  private loadParams(): void {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.token = queryParams.t;
    });
  }

  private confirmEmail(): void {
    if (!this.token) {
      this.loaded = true;
      return;
    }

    this.authService.confirmEmail(this.token).subscribe(() => {
      this.notification.success('E-mail confirmado com sucesso.');
      this.router.navigate(['autenticacao']);
    });
  }
}
