import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfigService } from '../../services/config';
import { CookieService } from '../../services/cookie';
import { cookieAccepted } from '../../store/actions';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectEnterprise } from '../../store/selectors';

@Component({
  selector: 'app-cookie-acceptance',
  templateUrl: './cookie-acceptance.component.html',
  styleUrls: ['./cookie-acceptance.component.scss'],
})
export class CookieAcceptanceComponent implements OnInit {
  showCookieAcceptance$ = this.cookieService.showCookieAcceptance$;

  enterprise$ = this.store.select(selectEnterprise);
  route_seguranca!: string;
  route_privacidade!: string;
  constructor(private cookieService: CookieService, private store: Store<IAppState>, private config: ConfigService) {}
  ngOnInit(): void {
    this.route_seguranca = this.config.getConfiguration('SEGURANCA_INFORMACAO_ROUTE');
    this.route_privacidade = this.config.getConfiguration('PRIVACIDADE_ROUTE');
  }

  onAgreeClick(): void {
    this.store.dispatch(cookieAccepted());
  }
}
