import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import months from 'src/app/core/data/months';
import { NotificationService } from 'src/app/core/services/notification';

interface FormData {
  billingYear?: string;
  billingMonth?: string;
  serviceDate?: string;
}

@Component({
  selector: 'app-modal-busca-extrato-copart',
  templateUrl: './modal-busca-extrato-copart.component.html',
  styleUrls: ['./modal-busca-extrato-copart.component.scss'],
})
export class ModalBuscaExtratoCopartComponent implements OnInit {
  @Output() finished = new EventEmitter<FormData>();

  @Output() canceled = new EventEmitter();

  formGroup!: FormGroup;

  months = months;

  constructor(private formBuilder: FormBuilder, private notification: NotificationService) {}

  ngOnInit(): void {
    this.buildForm();
  }

  onSubmit(): void {
    if (!this.validateForm()) {
      return;
    }

    const formValue = this.getFormValue();

    this.finished.emit(formValue);
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      billingYear: null,
      billingMonth: null,
      serviceDate: null,
    });
  }

  private validateForm(): boolean {
    const { billingYear, billingMonth, serviceDate } = this.formGroup.getRawValue();

    if (this.formGroup.invalid) {
      return false;
    }

    if (!billingYear && !serviceDate) {
      this.notification.info('Preencha pelo menos o Ano de Cobrança ou a Data de Serviço');
      return false;
    }

    if (billingMonth && !billingYear) {
      this.notification.info('O mês de cobrança só pode ser preenchido juntamente com o Ano de Cobrança');
      return false;
    }

    return true;
  }

  private getFormValue(): FormData {
    const { billingMonth, billingYear, serviceDate } = this.formGroup.getRawValue();

    if (serviceDate) {
      return {
        serviceDate: this.formatServiceDate(serviceDate),
      };
    }

    return {
      billingMonth,
      billingYear,
    };
  }

  /**
   * Formats date string DDMMAAAA to AAAAMMDD
   */
  private formatServiceDate(serviceDate: string): string {
    const day = serviceDate.slice(0, 2);
    const month = serviceDate.slice(2, 4);
    const year = serviceDate.slice(4, 8);

    return `${year}${month}${day}`;
  }
}
