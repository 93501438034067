<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['DADOS_CADASTRAIS' | configLabel, '']
  ]"
></app-breadcrumb>

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <mat-card class="mt-4">
    <h4>{{ 'DADOS_CADASTRAIS' | configLabel }}</h4>
    <div class="row max-w-1000 mt-4">
      <div class="col-md-6 col-lg-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Telefone Fixo</mat-label>
          <input type="text" matInput mask="(00) 0000-0000" formControlName="homePhone" />
          <mat-error>Preencha este campo corretamente</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 col-lg-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Telefone Celular</mat-label>
          <input type="text" matInput [mask]="cellPhoneMask" formControlName="cellNumber"/>
          <mat-error>Preencha este campo corretamente</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 col-lg-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>CEP</mat-label>
          <input type="text" matInput mask="00000-000" formControlName="cep" (blur)="onCepBlur()" />
          <mat-error>Preencha este campo corretamente</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 col-lg-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Logradouro</mat-label>
          <input type="text" matInput formControlName="address" />
          <mat-error>Preencha este campo corretamente</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 col-lg-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Número</mat-label>
          <input type="text" matInput formControlName="addressNumber" />
          <mat-error>Preencha este campo corretamente</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 col-lg-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Complemento</mat-label>
          <input type="text" matInput formControlName="addressComplement" />
          <mat-error>Preencha este campo corretamente</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 col-lg-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Bairro</mat-label>
          <input type="text" matInput formControlName="district" />
          <mat-error>Preencha este campo corretamente</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 col-lg-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Cidade</mat-label>
          <input type="text" matInput formControlName="city" />
          <mat-error>Preencha este campo corretamente</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 col-lg-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Estado</mat-label>
          <input type="text" matInput formControlName="uf" />
          <mat-error>Preencha este campo corretamente</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>E-mail</mat-label>
          <input type="email" matInput formControlName="email" />
          <mat-error>Preencha este campo corretamente</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="mt-3 d-flex justify-content-between">
      <a mat-stroked-button color="secondary" class="me-2" routerLink="/inicio">Voltar</a>
      <button mat-raised-button color="accent" type="submit" *ngIf="config.getConfiguration('CAN_EDIT_BENEFICIARY')">
        Salvar
      </button>
    </div>
  </mat-card>
</form>
