<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Declaração Anual de débito', '']
  ]"
></app-breadcrumb>

<mat-card class="mt-4">
  <div class="d-flex justify-content-between">
    <h4 class="mb-4">Declaração anual de quitação de débito</h4>
    <button mat-raised-button color="primary" (click)="print()" *ngIf="extratoCobranca">
      <mat-icon>print</mat-icon>
    </button>
  </div>

  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" *ngIf="!extratoCobranca">
    <mat-form-field appearance="outline">
      <mat-label>Ano base</mat-label>
      <mat-select formControlName="year">
        <mat-option *ngFor="let year of lastFiveYears" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="mt-3">
      <button mat-raised-button color="accent" type="submit">Buscar</button>
      <button mat-stroked-button color="secondary" class="ms-2">Voltar</button>
    </div>
  </form>

  <div *ngIf="extratoCobranca">
    <p class="text-muted mb-1">{{ currentDay }} de {{ currentMonth }} de {{ currentYear }}</p>
    <p class="text-muted">
      Prezado(a) {{ (user$ | async)?.nome }}. A {{ (enterprise$ | async)?.clienteNome }} declara que, para fins da Lei
      nº12.007 , as faturas relativas ao período de janeiro a dezembro do ano de {{ selectedYear }}, no que se refere ao
      plano de saúde por ela administrado, se encontram quitadas. Esta declaração substitui as quitações mensais do ano
      de {{ selectedYear }}
      mencionadas abaixo:
    </p>
    <ng-container *ngFor="let detail of extratoCobranca.details">
      <h2>Ano: {{ selectedYear }}</h2>
      <p class="mb-0">
        <span class="fw-bold">Operadora</span>
        : {{ detail.providerName }}
      </p>
      <p class="mb-0">
        <span class="fw-bold">CNPJ</span>
        : {{ detail.providerCGC | mask: '00.000.000/0000-00' }}
      </p>
      <p class="mb-0">
        <span class="fw-bold">Entidade</span>
        : {{ detail.entity }}
      </p>
      <p class="mb-0">
        <span class="fw-bold">Contrato</span>
        : {{ detail.contract }}
      </p>

      <table class="table table-hover table-striped mt-2">
        <thead>
          <tr>
            <th>Competência</th>
            <th>Vencimento</th>
            <th>Data da Baixa</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of detail.invoices">
            <td>{{ item.yearMonth }}</td>
            <td>{{ item.dueDate | dashToBar }}</td>
            <td>{{ item.dischargeDate | dashToBar }}</td>
            <td>{{ item.amount | currency: 'BRL' }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-3">
        <button mat-stroked-button color="secondary" class="ms-2" (click)="extratoCobranca = null" type="button">
          Voltar
        </button>
      </div>
    </ng-container>
  </div>
</mat-card>
