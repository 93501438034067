import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SegundaViaBoletoComponent } from './components/segunda-via-boleto';

const routes: Routes = [{ path: 'boleto', component: SegundaViaBoletoComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SegundaViaRoutingModule {}
