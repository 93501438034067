import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { IncomeReport } from '../../models/IncomeReport';

import { PaymentReportService } from 'src/app/core/services/payment-report';

@Component({
  selector: 'app-informe-de-pagamento',
  templateUrl: './informe-de-pagamento.component.html',
  styleUrls: ['./informe-de-pagamento.component.scss'],
})
export class InformeDePagamentoComponent implements OnInit {
  @ViewChild('printContainer') printContainer!: ElementRef;

  formGroup!: FormGroup;

  incomeReport!: IncomeReport;

  lastFiveYears = new Array(5).fill(null).map((_, i) => new Date().getFullYear() - i);

  lastYear = new Date().getFullYear() - 1;

  constructor(
    private formBuilder: FormBuilder,
    private paymentReportService: PaymentReportService,
    private loader: LoaderService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.criarFormulario();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Por favor, preencha os campos corretamente');
      return;
    }

    this.loader.show('Buscando dados de informe de pagamento..');

    this.paymentReportService
      .getIncomeReport(this.obterAnoSelecionado())
      .subscribe((incomeReport) => {
        this.incomeReport = incomeReport;
      })
      .add(() => {
        this.loader.hide();
      });
  }

  obterAnoSelecionado(): string {
    return this.formGroup.get('ano')?.value;
  }

  print(): void {
    print();
  }

  private criarFormulario(): void {
    this.formGroup = this.formBuilder.group({
      ano: [this.lastYear, Validators.required],
    });
  }
}
