import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleBeneficiary } from 'src/app/core/models/SimpleBeneficiary';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { LoaderService } from 'src/app/core/services/loader';

@Component({
  selector: 'app-tabela-cancelamento-imediato',
  templateUrl: './tabela-cancelamento-imediato.component.html',
  styleUrls: ['./tabela-cancelamento-imediato.component.scss'],
})
export class TabelaCancelamentoImediatoComponent implements OnInit {
  dataSource = new MatTableDataSource<SimpleBeneficiary>();

  displayedColumns = ['name', 'cpf', 'kinship', 'status'];

  constructor(
    private beneficiaryService: BeneficiaryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.setTable();
  }

  selectBeneficiary(beneficiary: SimpleBeneficiary): void {
    if (beneficiary.status !== 'Ativo') {
      return;
    }

    this.router.navigate(['..', beneficiary.idBeneficiary], { relativeTo: this.activatedRoute });
  }

  private setTable(): void {
    this.loader.show('Buscando titulares...');
    this.beneficiaryService
      .getHolders()
      .subscribe((beneficiaries) => {
        this.dataSource.data = beneficiaries;
      })
      .add(() => {
        this.loader.hide();
      });
  }
}
