<div *ngIf="step === 0">
  <h4>LEIA COM ATENÇÃO AS INFORMAÇÕES ABAIXO:</h4>
  <br />

  <p>
    <b>I.</b>
    Eventual ingresso em novo plano de saúde poderá importar:
  </p>

  <p>
    <b>a)</b>
    No cumprimento de novos períodos de carência, observado o disposto no inciso V do artigo 12, da Lei nº 9.656, de 3
    de junho de 1998;
  </p>

  <p>
    <b>b)</b>
    Na perda do direito à portabilidade de carências, caso não tenha sido este o motivo do pedido, nos termos previstos
    na RN nº 186, de 14 de janeiro de 2009, que dispõe, em especial, sobre a regulamentação da portabilidade das
    carências previstas no inciso V do art. 12 da Lei nº 9.656, de 3 de junho de 1998;
  </p>

  <p>
    <b>c)</b>
    No preenchimento de nova declaração de saúde, e, caso haja doença ou lesão preexistente - DLP, no cumprimento de
    Cobertura Parcial Temporária - CPT, que determina, por um período ininterrupto de até 24 meses, a partir da data da
    contratação ou adesão ao novo plano, a suspensão da cobertura de Procedimentos de Alta Complexidade (PAC), leitos de
    alta tecnologia e procedimentos cirúrgicos;
  </p>

  <p>
    <b>d)</b>
    Na perda imediata do direito de remissão, quando houver, devendo o beneficiário arcar com o pagamento de um novo
    contrato de plano de saúde que venha a contratar;
  </p>

  <p>
    <b>II.</b>
    Efeito imediato e caráter irrevogável da solicitação de cancelamento do contrato ou exclusão de beneficiário, a
    partir da ciência da operadora ou administradora de benefícios;
  </p>
  <br />

  <p>
    <b>III.</b>
    As contraprestações pecuniárias vencidas e/ou eventuais coparticipações devidas, nos planos em pré-pagamento ou em
    pós pagamento, pela utilização de serviços realizados antes da solicitação de cancelamento ou exclusão do plano de
    saúde são de responsabilidade do beneficiário;
  </p>
  <br />

  <p>
    <b>IV.</b>
    As despesas decorrentes de eventuais utilizações dos serviços pelos beneficiários após a data de solicitação de
    cancelamento ou exclusão do plano de saúde, inclusive nos casos de urgência ou emergência, correrão por sua conta;
  </p>
  <br />

  <p>
    <b>V.</b>
    A exclusão do beneficiário titular do contrato coletivo empresarial ou por adesão observará as disposições
    contratuais quanto à exclusão ou não dos dependentes, conforme o disposto no inciso II do parágrafo único do artigo
    18, da RN nº 195, de 14 de julho de 2009, que dispõe sobre a classificação e características dos planos privados de
    assistência à saúde, regulamenta a sua contratação, institui a orientação para contratação de planos privados de
    assistência à saúde e dá outras providências.
  </p>
  <br />

  <p>Atenciosamente. {{ (enterprise$ | async)?.clienteNome }}</p>

  <p>ANS nº {{ (enterprise$ | async)?.registroAns }}</p>

  <br />

  <mat-checkbox [formControl]="checkedControl">Eu li e concordo com as cláusulas expostas pela ANS.</mat-checkbox>

  <div class="mt-3">
    <button mat-raised-button color="accent" type="submit" [disabled]="!checkedControl.value" (click)="setStep(1)">
      Continuar
    </button>
    <button mat-stroked-button color="secondary" class="ms-2" (click)="canceled.emit()" type="button">Voltar</button>
  </div>
</div>

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" *ngIf="step === 1">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="mb-3">Dados para possível reembolso</h2>
      </div>
      <div class="col-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Banco</mat-label>
          <mat-select formControlName="bank">
            <mat-option *ngFor="let bank of banks" [value]="bank.code">{{ bank.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Agência</mat-label>
          <input type="text" matInput formControlName="agency" mask="0000" />
          <mat-error>Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Digito da agência</mat-label>
          <input type="text" matInput formControlName="agencyDigit" mask="A" />
          <mat-error>Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Conta</mat-label>
          <input type="text" matInput formControlName="account" mask="99999999" />
          <mat-error>Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Dígito da Conta</mat-label>
          <input type="text" matInput formControlName="accountDigit" mask="A" />
          <mat-error>Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Tipo da Conta</mat-label>
          <mat-select formControlName="accountType">
            <mat-option value="1">Corrente</mat-option>
            <mat-option value="2">Poupança</mat-option>
          </mat-select>
          <mat-error>Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Nome</mat-label>
          <input type="text" matInput formControlName="name" />
          <mat-error>Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>CPF/CNPJ da conta</mat-label>
          <input type="text" matInput formControlName="cgc" mask="CPF_CNPJ" />
          <mat-error>Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>E-mail</mat-label>
          <input type="email" matInput formControlName="email" />
          <mat-error>Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Motivo do cancelamento</mat-label>
          <textarea matInput formControlName="reasonCancellation"></textarea>
          <mat-error>Este campo é obrigatório</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="col-12">
      <div class="mt-3">
        <button mat-raised-button color="accent" type="submit">Prosseguir com cancelamento</button>
        <button mat-stroked-button color="secondary" class="ms-2" (click)="canceled.emit()" type="button">
          Voltar
        </button>
      </div>
    </div>
  </div>
</form>

<div class="container" *ngIf="step === 2">
  <form class="row" [formGroup]="passwordForm" (ngSubmit)="emitFinished()">
    <div class="col-12">
      <h2 class="mb-3">Insira sua senha para continuar</h2>
    </div>
    <div class="col-md-4 col-lg-3">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Senha</mat-label>
        <input type="password" matInput formControlName="password" />
        <mat-error>Este campo é obrigatório</mat-error>
      </mat-form-field>
    </div>

    <div class="col-12">
      <div class="mt-3">
        <button mat-raised-button color="accent" type="submit">Prosseguir</button>
        <button mat-stroked-button color="secondary" class="ms-2" (click)="canceled.emit()" type="button">
          Voltar
        </button>
      </div>
    </div>
  </form>
</div>
