<form [formGroup]="formGroup" *ngIf="formGroup" (ngSubmit)="onSubmit()">
  <div class="beneficiary-documents-list">
    <ng-container formArrayName="documents">
      <app-beneficiary-document-input
        *ngFor="let documentControl of documentsFormArray.controls; let i = index"
        [document]="documents[i]"
        [hasValue]="hasValue(i)"
        [sent]="sent"
        (changed)="onInputChanged($event, i)"
        (removed)="onRemoved(i)"
      ></app-beneficiary-document-input>
    </ng-container>
  </div>
  <div class="mt-3">
    <button mat-stroked-button color="secondary" type="button" (click)="onBackClick()">Voltar</button>
    <button mat-raised-button color="accent" type="submit" class="ms-2">Continuar</button>
  </div>
</form>
