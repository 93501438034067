import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { User } from '../../models/User';
import { MenuItem, SidenavService } from '../../services/sidenav/sidenav.service';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectIsLogged, selectSidenavOpened, selectTenantNome, selectUser } from '../../store/selectors';
import * as FontAwesome from '@fortawesome/free-solid-svg-icons';
import { MediaService } from '../../services/media';
import { closeSidenav } from '../../store/actions';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @ViewChild('drawer') drawer!: MatDrawer;

  user!: User;

  fontAwersome = FontAwesome;

  activatedItem: MenuItem | null = null;

  menuItems$ = this.sidenavService.menuItems$;

  isLoggedIn$ = this.store.select(selectIsLogged).pipe(tap(() => (this.activatedItem = null)));

  loggedUser$ = this.store.select(selectUser);

  sidenavOpened$ = this.store.select(selectSidenavOpened);

  smallScreen$ = this.mediaService.smallScreen$;

  tenantNome$ = this.store.select(selectTenantNome);

  constructor(
    private store: Store<IAppState>,
    private sidenavService: SidenavService,
    private mediaService: MediaService
  ) {}

  @HostListener('click', ['$event.target'])
  onClick(element: HTMLElement) {
    const hasBackdropClass = element.classList.contains('mat-drawer-backdrop');

    if (hasBackdropClass) {
      this.store.dispatch(closeSidenav());
    }
  }

  toggleActivatedMenu(item: MenuItem): void {
    item.onClick?.();

    if (this.isMenuActivated(item)) {
      this.clearActivatedMenu();
      return;
    }

    this.activatedItem = item;
  }

  clearActivatedMenu(): void {
    this.activatedItem = null;

    this.closeSidenavIfSmallScreen();
  }

  isMenuActivated(item: MenuItem): boolean {
    return this.activatedItem === item;
  }

  getItemStyle(item: MenuItem): { [key: string]: string } {
    const length = item.children?.length ?? 0;

    if (this.isMenuActivated(item)) {
      return { 'max-height': `${length * 36}px` };
    }

    return {};
  }

  private closeSidenavIfSmallScreen(): void {
    this.smallScreen$
      .pipe(
        take(1),
        filter((v) => !!v)
      )
      .subscribe(() => {
        this.store.dispatch(closeSidenav());
      });
  }
}
