<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Informe de pagamentos', '']
  ]"
></app-breadcrumb>

<mat-card class="mt-4">
  <div class="d-flex justify-content-between">
    <h4 class="mb-4">Informe de pagamentos</h4>
    <button mat-raised-button color="primary" (click)="print()" *ngIf="incomeReport">
      <mat-icon>print</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="!incomeReport">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>Ano base</mat-label>
        <mat-select formControlName="ano">
          <mat-option *ngFor="let year of lastFiveYears" [value]="year">{{ year }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="mt-3">
        <button mat-raised-button color="accent" type="submit">Buscar</button>
        <button mat-stroked-button color="secondary" class="ms-2">Voltar</button>
      </div>
    </form>
  </ng-container>

  <app-payment-report-table
    *ngIf="incomeReport"
    [incomeReport]="incomeReport"
    [year]="obterAnoSelecionado()"
  ></app-payment-report-table>
</mat-card>
