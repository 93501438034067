<div class="app-container">
  <app-header></app-header>
  <app-sidenav>
    <main [class.logged]="isLogged">
      <router-outlet></router-outlet>
    </main>
  </app-sidenav>

  <app-footer></app-footer>
</div>

<app-loader></app-loader>

<app-cookie-acceptance *ngIf="isLogged"></app-cookie-acceptance>
<app-consent-acceptance *ngIf="isLogged"></app-consent-acceptance>
