<form class="auth-container" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h5 class="w-100 mb-3">Insira suas credenciais</h5>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Nome</mat-label>
    <input type="text" matInput formControlName="name" />
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>E-mail</mat-label>
    <input type="email" matInput formControlName="email" />
    <mat-error *ngIf="formGroup.get('email')?.hasError('required')">Este campo é obrigatório</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Senha</mat-label>
    <input type="password" matInput formControlName="password" />
    <mat-error *ngIf="formGroup.get('password')?.hasError('required')">Este campo é obrigatório</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Confirmação da Senha</mat-label>
    <input type="password" matInput formControlName="passwordConfirmation" />
    <mat-error *ngIf="formGroup.get('passwordConfirmation')?.hasError('required')">Este campo é obrigatório</mat-error>
    <mat-error *ngIf="formGroup.get('passwordConfirmation')?.hasError('different')">As senhas não coincidem</mat-error>
  </mat-form-field>

  <div class="links-container">
    <span routerLink="/">Voltar</span>
  </div>

  <button mat-raised-button color="accent" type="submit" class="w-100">ENTRAR</button>
</form>
