import { Component, Input } from '@angular/core';

export type BreadcrumbStep = [string, string | string[], any?][];

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() steps!: BreadcrumbStep;

  get stepsAmount(): number {
    return this.steps.length;
  }

  extractRouterLink(link: string | string[]): string {
    if (Array.isArray(link)) {
      return '/' + link.join('/');
    }

    return '/' + link;
  }
}
