import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { closeSidenav, openSidenav } from '../../store/actions';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectSidenavOpened } from '../../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  screenWidth$ = new BehaviorSubject<number>(window.innerWidth);

  smallScreen$ = this.screenWidth$.pipe(map((sw) => (sw ?? 0) < 900));

  largeScreen$ = this.screenWidth$.pipe(map((sw) => (sw ?? 0) >= 900));

  navbarOpened$ = this.store.select(selectSidenavOpened);

  screenWidthResized$ = fromEvent(window, 'resize').pipe(
    map((event) => (event?.target as Window)?.innerWidth),
    distinctUntilChanged(),
    map((n) => n as number)
  );

  constructor(private store: Store<IAppState>) {
    this.registerListener();
    this.dispatchSidenavToggler(window.innerWidth < 900);
  }

  registerListener(): void {
    this.screenWidthResized$.subscribe((screenWidth) => {
      this.screenWidth$.next(screenWidth);
    });

    this.smallScreen$
      .pipe(
        switchMap((isSmallScreen) =>
          this.navbarOpened$.pipe(
            take(1),
            filter((isOpened) => isOpened === isSmallScreen)
          )
        )
      )
      .subscribe((isSmallScreen) => {
        this.dispatchSidenavToggler(isSmallScreen);
      });
  }

  private dispatchSidenavToggler(isSmallScreen: boolean): void {
    this.store.dispatch(isSmallScreen ? closeSidenav() : openSidenav());
  }
}
