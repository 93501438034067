export interface ICreateUserRequest {
  email: string;
  password: string;
  passwordConfirmation: string;
  cgc: string;
  branch: string;
  tenantId: string;
  idClient: string;
}

export class CreateUserRequest {
  email: string;
  password: string;
  passwordConfirmation: string;
  cgc: string;
  branch: string;
  tenantId: string;
  idClient: string;

  constructor(data: ICreateUserRequest) {
    this.email = data.email;
    this.password = data.password;
    this.passwordConfirmation = data.passwordConfirmation;
    this.cgc = data.cgc;
    this.branch = data.branch;
    this.tenantId = data.tenantId;
    this.idClient = data.idClient;
  }
}
