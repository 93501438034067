export interface IDefaultErrorResponse {
  statusCode: number;
  message: string;
  details: string[];
}

export class DefaultErrorResponse {
  statusCode: number;
  message: string;
  details: string[];

  constructor(data: IDefaultErrorResponse) {
    this.statusCode = data.statusCode;
    this.message =
      data.message ||
      'Houve um erro inesperado. O servidor pode estar em processo de atualização. Tente novamente em alguns segundos.';
    this.details = data.details || [];
  }
}
