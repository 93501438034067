export interface IRecoveryPassRequest {
  password: string;
  passwordConfirm: string;
  token: string;
}

export class RecoveryPassRequest {
  password: string;
  passwordConfirm: string;
  token: string;

  constructor(data: IRecoveryPassRequest) {
    this.password = data.password;
    this.passwordConfirm = data.passwordConfirm;
    this.token = data.token;
  }
}
