import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { User } from 'src/app/core/models/User';
import { AuthService } from 'src/app/core/services/auth';
import { setAuthData } from 'src/app/core/store/actions';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { endpoints } from 'src/environments/endpoints';

@Component({
  selector: 'app-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss'],
})
export class LoginRedirectComponent implements OnInit {
  token!: string;
  redirectTo!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<IAppState>,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadParams();
    this.setAuthData();
  }

  private loadParams(): void {
    this.activatedRoute.queryParams.subscribe(({ token, redirectTo }) => {
      this.token = token;
      this.redirectTo = redirectTo;
    });
  }

  private setAuthData(): void {
    const decoded = jwt_decode<any>(this.token);

    this.authService.getUserByToken(this.token).subscribe((user) => {
      this.store.dispatch(
        setAuthData({
          payload: {
            token: {
              expiresIn: decoded.exp,
              token: this.token,
              tokenType: 'Bearer',
            },
            user,
          },
        })
      );

      this.router.navigateByUrl(this.redirectTo);
    });
  }
}
