<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Cancelamento Imediato', '']
  ]"
></app-breadcrumb>

<mat-card class="mt-4">
  <h4>Cancelamento Imediato</h4>
  <p class="text-muted">Selecione o titular</p>

  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.name }}</td>
    </ng-container>

    <ng-container matColumnDef="cpf">
      <th mat-header-cell *matHeaderCellDef>CPF</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.cpf | mask: '000.000.000-00' }}</td>
    </ng-container>

    <ng-container matColumnDef="kinship">
      <th mat-header-cell *matHeaderCellDef>Parentesco</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.kinship }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let beneficiary">
        <span class="p-1 px-3 badge bg-{{ beneficiary.status === 'Ativo' ? 'success' : 'danger' }}">
          {{ beneficiary.status }}
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let beneficiary; columns: displayedColumns"
      [class.c-pointer]="beneficiary.status === 'Ativo'"
      (click)="selectBeneficiary(beneficiary)"
    ></tr>
  </table>
</mat-card>
