import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Favicons } from './core/favicons/favicons';
import { IAppState } from './core/store/interfaces/IAppState';
import { selectEnterprise, selectIsLogged } from './core/store/selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLogged!: boolean;
  private favicons: Favicons;
  enterprise$ = this.store.select(selectEnterprise);
  constructor(private store: Store<IAppState>, private cd: ChangeDetectorRef, favicons: Favicons) {
    this.favicons = favicons;
  }

  ngOnInit(): void {
    this.enterprise$.subscribe((res) => {
      this.useFavicon(res?.logoTransparenteUrl as string);
    });

    this.store.select(selectIsLogged).subscribe((v) => {
      this.isLogged = v;
      this.cd.detectChanges();
    });
  }

  public useFavicon(href: string): void {
    this.favicons.activate(href);
  }
}
