import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { BehaviorSubject, forkJoin, take } from 'rxjs';
import { TipoTermo } from '../../models/ConsentForm';
import { ConfigService } from '../../services/config';
import { ConsentFormService } from '../../services/consentForm/consent-form.service';
import { CoreActions, isPolicysAccepted, policysAccepted, setConsentForms } from '../../store/actions';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectEnterprise, selectUser } from '../../store/selectors';

@Component({
  selector: 'app-consent-acceptance',
  templateUrl: './consent-acceptance.component.html',
  styleUrls: ['./consent-acceptance.component.scss'],
})
export class ConsentAcceptanceComponent implements OnInit, OnDestroy {
  enterprise$ = this.store.select(selectEnterprise);
  user$ = this.store.select(selectUser);
  showPolicysAcceptance$ = new BehaviorSubject(false);
  route_seguranca!: string;
  route_privacidade!: string;
  constructor(
    private consentFormService: ConsentFormService,
    private store: Store<IAppState>,
    private actions$: Actions,
    private config: ConfigService
  ) {}

  ngOnDestroy(): void {
    this.store.dispatch(isPolicysAccepted());
  }
  ngOnInit(): void {
    this.route_seguranca = this.config.getConfiguration('SEGURANCA_INFORMACAO_ROUTE');
    this.route_privacidade = this.config.getConfiguration('PRIVACIDADE_ROUTE');
    this.showPolicysAcceptance$.next(false);
    this.actions$.pipe(ofType(CoreActions.ShowPolicysAcceptance)).subscribe(() => {
      this.showPolicysAcceptance$.next(true);
    });

    this.actions$.pipe(ofType(CoreActions.PolicysAccepted)).subscribe(() => {
      this.showPolicysAcceptance$.next(false);
    });

    this.actions$.pipe(ofType(CoreActions.IsPolicysAccepted)).subscribe(() => {
      this.showPolicysAcceptance$.next(false);
    });
  }

  onAgreeClick(): void {
    const currentDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SS");

    forkJoin({
      enterprise: this.enterprise$.pipe(take(1)),
      user: this.user$.pipe(take(1)),
    }).subscribe(({ enterprise, user }) => {
      if (!user || !enterprise) return;

      const consentForms = [
        {
          acceptanceDate: currentDate,
          idClient: user?.idClient.toString(),
          tenantId: enterprise?.tenantProtheus,
          tenantName: enterprise?.tenantNome,
          termo: TipoTermo.PoliticaPrivacidade,
          version: '1',
          ip: '',
        },
        {
          acceptanceDate: currentDate,
          idClient: user?.idClient.toString(),
          tenantId: enterprise?.tenantProtheus,
          tenantName: enterprise?.tenantNome,
          termo: TipoTermo.PoliticaSeguranca,
          version: '1',
          ip: '',
        },
      ];

      this.store.dispatch(setConsentForms({ payload: consentForms }));
      this.store.dispatch(policysAccepted());
    });
  }
}
