import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import banks from 'src/app/core/data/banks';
import { NotificationService } from 'src/app/core/services/notification';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectEnterprise } from 'src/app/core/store/selectors';
import { FormModalCancelamentoImediato } from '../../models/FormModalCancelamentoImediato';

@Component({
  selector: 'app-modal-cancelamento-imediato',
  templateUrl: './modal-cancelamento-imediato.component.html',
  styleUrls: ['./modal-cancelamento-imediato.component.scss'],
})
export class ModalCancelamentoImediatoComponent implements OnInit {
  @Output() finished = new EventEmitter<FormModalCancelamentoImediato>();
  @Output() canceled = new EventEmitter();

  banks = banks;

  formGroup!: FormGroup;

  passwordForm!: FormGroup;

  step = 0;

  checkedControl = new FormControl(false);

  enterprise$ = this.store.select(selectEnterprise);

  constructor(
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    private store: Store<IAppState>
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Preencha os campos corretamente.');
      return;
    }

    this.step = 2;
  }

  emitFinished(): void {
    if (this.passwordForm.invalid) {
      this.notification.info('Por favor, preencha os campos corretamente.');
      return;
    }

    this.finished.emit(this.getFormModalCancelamentoImediato());
  }

  setStep(step: number): void {
    this.step = step;
  }

  private getFormModalCancelamentoImediato(): FormModalCancelamentoImediato {
    const formValue = this.formGroup.getRawValue();
    return new FormModalCancelamentoImediato({
      account: formValue.account,
      accountDigit: formValue.accountDigit,
      accountType: formValue.accountType,
      agency: formValue.agency,
      agencyDigit: formValue.agencyDigit,
      bank: formValue.bank,
      cgc: formValue.cgc,
      email: formValue.email,
      name: formValue.name,
      reasonCancellation: formValue.reasonCancellation,

      password: this.passwordForm.get('password')?.value,
    });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      account: [null],
      accountDigit: [null],
      accountType: [null],
      agency: [null],
      agencyDigit: [null],
      bank: [null],
      cgc: [null, Validators.required],
      email: [null, Validators.required],
      name: [null, Validators.required],
      reasonCancellation: [null, Validators.required],
    });

    this.passwordForm = this.formBuilder.group({
      password: [null, Validators.required],
    });
  }
}
