<form class="auth-container" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h5 class="w-100 mb-3">Insira sua nova senha</h5>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Senha</mat-label>
    <input type="password" matInput formControlName="password" />
    <mat-error *ngIf="formGroup.get('password')?.hasError('required')">Este campo é obrigatório</mat-error>
    <mat-error *ngIf="formGroup.get('password')?.hasError('minlength')">
      A senha deve ter no mínimo 8 caracteres
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Confirme sua senha</mat-label>
    <input type="password" matInput formControlName="passwordConfirm" />
    <mat-error *ngIf="formGroup.get('passwordConfirm')?.hasError('required')">Este campo é obrigatório</mat-error>
    <mat-error *ngIf="formGroup.get('passwordConfirm')?.hasError('invalid')">As senhas não coincidem</mat-error>
    <mat-error *ngIf="formGroup.get('passwordConfirm')?.hasError('minlength')">
      A senha deve ter no mínimo 8 caracteres
    </mat-error>
  </mat-form-field>

  <div class="links-container">
    <span routerLink="/">Voltar</span>
  </div>

  <button mat-raised-button color="accent" type="submit" class="w-100">ALTERAR SENHA</button>
</form>
