import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { NgxMaskModule } from 'ngx-mask';

import { PipesModule } from '../pipes/pipes.module';
import { BreadcrumbComponent } from './breadcrumb';
import { ConsentAcceptanceComponent } from './consent-acceptance/consent-acceptance.component';
import { CookieAcceptanceComponent } from './cookie-acceptance';
import { InvoiceTableComponent } from './invoice-table';
import { LoaderComponent } from './loader';
import { ModalComponent } from './modal';
import { DialogComponent } from './modal/dialog';
import { NotFoundComponent } from './not-found';
import { PaymentReportTableComponent } from './payment-report-table/payment-report-table.component';

const COMPONENTS = [
  BreadcrumbComponent,
  LoaderComponent,
  ModalComponent,
  InvoiceTableComponent,
  CookieAcceptanceComponent,
  ConsentAcceptanceComponent,
  NotFoundComponent,
  PaymentReportTableComponent,
];

@NgModule({
  declarations: [...COMPONENTS, DialogComponent, ConsentAcceptanceComponent],
  imports: [
    CommonModule,
    PipesModule,
    NgxMaskModule.forRoot(),
    RouterModule,
    MatIconModule,
    MatDialogModule,
    MatTableModule,
    MatCardModule,
    MatButtonModule,
  ],
  exports: [...COMPONENTS],
})
export class ComponentsModule {}
