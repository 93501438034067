import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Observable, switchMap } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { ConsentForm } from '../../models/ConsentForm';
import { IpService } from '../ip';

@Injectable({
  providedIn: 'root',
})
export class ConsentFormService {
  constructor(private actions$: Actions, private ipService: IpService, private http: HttpClient) {}

  getConsentForm(idClient: string | undefined): Observable<ConsentForm[]> {
    const baseURL = endpoints.consentForm;
    const url = `${baseURL}/list/consents/${idClient}`;

    return this.http.get<ConsentForm[]>(url);
  }

  saveConsent(consentForms: ConsentForm[]): Observable<ConsentForm[]> {
    const baseURL = endpoints.consentForm;
    const url = `${baseURL}`;

    return this.ipService.getUserIp().pipe(
      switchMap((ip) => {
        let consents: ConsentForm[] = [];
        consentForms.forEach((c) => {
          consents.push({ ...c, ip: ip });
        });
        return this.http.post<ConsentForm[]>(url, consents);
      })
    );
  }
}
