import { Pipe, PipeTransform } from '@angular/core';
import { onlyNumbers } from '../../utils/helpers';

@Pipe({
  name: 'protheusDate',
})
export class ProtheusDatePipe implements PipeTransform {
  transform(value: string): string {
    value = onlyNumbers(value);
    const year = value.slice(0, 4);
    const month = value.slice(4, 6);
    const day = value.slice(6, 8);

    return [day, month, year].filter(Boolean).join('/');
  }
}
