<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Carta de quitação', '']
  ]"
></app-breadcrumb>

<mat-card class="mt-4">
  <div class="d-flex justify-content-between">
    <h4 class="mb-4">Carta de quitação</h4>
    <button mat-raised-button color="primary" (click)="print()" *ngIf="cartaQuitacao">
      <mat-icon>print</mat-icon>
    </button>
  </div>

  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" *ngIf="!cartaQuitacao">
    <mat-form-field appearance="outline">
      <mat-label>Ano base</mat-label>
      <mat-select formControlName="year">
        <mat-option *ngFor="let year of lastFiveYears" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="ms-3">
      <mat-label>Mês base</mat-label>
      <mat-select formControlName="month">
        <mat-option *ngFor="let month of months" [value]="month.value">{{ month.description }}</mat-option>
      </mat-select>
      <mat-error>Este campo é obrigatório</mat-error>
    </mat-form-field>

    <div class="mt-3">
      <button mat-raised-button color="accent" type="submit">Buscar</button>
      <button mat-stroked-button color="secondary" class="ms-2">Voltar</button>
    </div>
  </form>

  <div *ngIf="cartaQuitacao">
    <p class="text-muted">
      Prezado(a): {{ (user$ | async)?.nome }},
      <br />
      Recebemos de {{ (user$ | async)?.nome }}, CPF/CNPJ nº {{ (user$ | async)?.cgc ?? '' | mask: 'CPF_CNPJ' }} titular
      do contrato coletivo , o(s) valore(s) descrito(s) abaixo:
    </p>

    <ng-container *ngFor="let detail of cartaQuitacao.details">
      <h4>Ano Mês: {{ selectedYear }}/{{ selectedMonth }}</h4>
      <p class="mb-0 mt-3">
        <span class="fw-bold">Operadora</span>
        : {{ detail.providerName }}
      </p>

      <p class="mb-0">
        <span class="fw-bold">Administradora</span>
        : {{ (enterprise$ | async)?.clienteNome }}
      </p>

      <p class="mb-0">
        <span class="fw-bold">CNPJ da administradora</span>
        : {{ (enterprise$ | async)?.cnpj }}
      </p>

      <p class="mb-0">
        <span class="fw-bold">Entidade</span>
        : {{ detail.entity }}
      </p>
      <p class="mb-0">
        <span class="fw-bold">Contrato</span>
        : {{ detail.contract }}
      </p>
      <p class="mb-0">
        <span class="fw-bold">Cliente</span>
        : {{ (user$ | async)?.nome }}
      </p>

      <table class="table table-hover table-striped mt-3">
        <thead>
          <tr>
            <th>Competência</th>
            <th>Vencimento</th>
            <th>Data da Baixa</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of detail.invoices">
            <td>{{ item.yearMonth }}</td>
            <td>{{ item.dueDate | dashToBar }}</td>
            <td>{{ item.dischargeDate | dashToBar }}</td>
            <td>{{ item.amount | currency: 'BRL' }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-3">
        <button mat-stroked-button color="secondary" class="ms-2" routerLink="../" type="button">Voltar</button>
      </div>
    </ng-container>
  </div>
</mat-card>
