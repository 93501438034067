import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ViaCepResponse } from 'src/app/core/models/ViaCepResponse';
import { CepService } from 'src/app/core/services/cep';
import { ConfigService } from 'src/app/core/services/config';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { CustomValidators } from 'src/app/core/utils/custom-validators';
import {
  getCellPhoneMask,
  getDDDFromPhoneNumber,
  getPhoneNumberWithoutDDD,
  isValidCep,
} from 'src/app/core/utils/helpers';
import { Client } from '../../models/Client';
import { ClientService } from '../../services/client';

@Component({
  selector: 'app-dados-de-cobranca',
  templateUrl: './dados-de-cobranca.component.html',
  styleUrls: ['./dados-de-cobranca.component.scss'],
})
export class DadosDeCobrancaComponent implements OnInit, OnDestroy {
  formGroup!: FormGroup;

  destroyed$ = new Subject<void>();

  cellPhoneMask!: string;

  client!: Client;

  constructor(
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private cepService: CepService,
    private loader: LoaderService,
    private notification: NotificationService,
    public config: ConfigService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.loadClient();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onCepBlur(): void {
    const cep = this.formGroup.get('cep')?.value;

    if (!isValidCep(cep)) {
      return;
    }

    this.loadCEP();
  }

  loadCEP(): void {
    const cep = this.formGroup.get('cep')?.value;

    this.loader.show('Carregando dados do CEP');
    this.cepService
      .getCepData(cep)
      .subscribe((cepData) => {
        this.fillFormByCepData(cepData);
      })
      .add(() => {
        this.loader.hide();
      });
  }

  onSubmit(): void {
    const updatedClientData = this.getUpdatedClientData();
    this.loader.show('Atualizando dados...');
    this.clientService
      .updateClient(updatedClientData)
      .subscribe((client) => {
        this.client = {
          ...this.client,
          ...client,
        };

        this.notification.success('Dados atualizados com sucesso!');
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      homePhone: [null, [Validators.required]],
      cellNumber: [null, [Validators.required]],
      cep: [null, [Validators.required]],
      address: [null, [Validators.required]],
      addressNumber: [null, [Validators.required]],
      addressComplement: [null],
      district: [null, [Validators.required]],
      city: [null, [Validators.required]],
      uf: [null, [Validators.required]],
      email: [null, [Validators.required, CustomValidators.email]],
    });
    this.registerCellPhoneMask();

    if (!this.config.getConfiguration('CAN_EDIT_BENEFICIARY')) {
      this.formGroup?.disable();
    }

    if (!this.config.getConfiguration('CAN_EDIT_BENEFICIARY_CELLPHONE')) {
      this.formGroup?.controls.cellNumber?.disable();
    }

    if (!this.config.getConfiguration('CAN_EDIT_BENEFICIARY_EMAIL')) {
      this.formGroup?.controls.email?.disable();
      }
  }

  private registerCellPhoneMask(): void {
    this.formGroup
      .get('cellNumber')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.cellPhoneMask = getCellPhoneMask(value);
        this.cd?.detectChanges();
      });
  }

  private fillFormByCepData(cepData: ViaCepResponse): void {
    this.formGroup.patchValue({
      address: cepData.logradouro,
      district: cepData.bairro,
      city: cepData.localidade,
      uf: cepData.uf,
    });
  }

  private loadClient(): void {
    this.loader.show(`Buscando dados...`);
    this.clientService
      .getClient()
      .subscribe((client) => {
        this.client = client;
        this.setClient(client);
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private setClient(client: Client): void {
    this.formGroup.patchValue({
      homePhone: `${client.dddHomePhone}${client.homePhone}`,
      cellNumber: `${client.dddCell}${client.cellNumber}`,
      cep: client.cep,
      address: client.address,
      addressNumber: client.addressNumber,
      addressComplement: client.addressComplement,
      district: client.district,
      city: client.city,
      uf: client.uf,
      email: client.email,
    });
  }

  private getUpdatedClientData(): Client {
    const form = this.formGroup.getRawValue();
    return {
      address: form.address,
      addressComplement: form.addressComplement,
      addressNumber: form.addressNumber,
      cep: form.cep,
      city: form.city,
      district: form.district,
      email: form.email,
      uf: form.uf,
      dddCell: getDDDFromPhoneNumber(form.cellNumber),
      cellNumber: getPhoneNumberWithoutDDD(form.cellNumber),
      dddHomePhone: getDDDFromPhoneNumber(form.homePhone),
      homePhone: getPhoneNumberWithoutDDD(form.homePhone),
      cgc: this.client.cgc,
      idClient: this.client.idClient,
      name: this.client.name,
    };
  }
}
