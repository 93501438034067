export default {
  'Vale Refeição/Alimentação': [
    {
      question: 'Há desconto em folha?',
      answer:
        'Sim, há desconto em folha, conforme PAT de até 20% ou de acordo com CCT. Exceto para estagiários. Para consulta é necessário abrir chamado.',
    },
    {
      question: 'Há reajuste de valor?',
      answer: 'O reajuste de valor acontece mediante atualização da CCT sindical.',
    },
    {
      question: 'É pago em dinheiro?',
      answer:
        'O benefício não será pago em dinheiro, o crédito será realizado diretamente no cartão Flash desde o primeiro mês.',
    },
    {
      question: 'Em quantos dias estará disponivel meu cartão Flash?',
      answer: 'Em torno de 5 dias úteis a partir da emissão, o cartão será enviado para sua residência.',
    },
    {
      question: 'Quando o benefício estará disponível?',
      answer: 'No último dia útil do mês',
    },
    {
      question: 'O crédito disponivel no último dia útil é para qual período de utilização?',
      answer: `O crédito que entrará no cartão no último dia útil refere-se a utilização do mês seguinte.
                Ex.: Crédito disponível no dia 30/09 - Período para utilização : 01/10 a 31/10`,
    },
    {
      question: 'Quais estabelecimentos aceitam o Cartão Flash?',
      answer: `Seu Flash pode ser utilizado em qualquer estabelecimento que aceite a Bandeira Mastercard e em território nacional. Seu saldo poderá ser utilizando como Refeição e Alimentação.`,
    },
    {
      question: 'Como consultar o Saldo do Cartão Flash?',
      answer:
        'Você pode consultar seu saldo pelo app Flash, pelo site <a href="https://user.flashapp.com.br/">https://user.flashapp.com.br/</a> ou pelo WhatsApp (11) 4118-048',
    },
    {
      question: 'Perdi meu Cartão Flash, o que faço? Irei perder o saldo que havia no mesmo?',
      answer: `
        Em caso de perda ou roubo o primeiro passo é seguir com o bloqueio do seu cartão e para isso, siga o passo a passo: <br />
        Acesse seu aplicativo. <br />
        Clique em Meus acessos > Ver todos > Cartões > Bloquear <br />
        Confirme o bloqueio e pronto! Seu cartão está bloqueado e o saldo está seguro. <br />
        Após o bloqueio do cartão, você pode solicitar segunda via através do app. 
      `,
    },
    {
      question: 'Bloqueei meu cartão por perda, mas depois o encontrei. Posso desbloquear?',
      answer:
        'Nesse caso não é possível fazer o desbloqueio. Será necessário solicitar segunda via e  um outro cartão será enviado para você com prazo de entrega de até 5 dias úteis.',
    },
    {
      question: 'Fui demitido, meu Cartão Flash é automaticamente cancelado?',
      answer: 'Você terá 90 dias para utilizar o saldo do seu cartão.',
    },
    {
      question: 'Qual a minha modalidade na Flash? Vale alimentação ou Vale Refeição?',
      answer: 'Agora você poderá utilizar seu saldo nas duas modalidades.',
    },
    {
      question: 'Atualizar opção de Vale Alimentação para Vale Refeição ou contrário.',
      answer:
        'Agora não será mais necessário solicitar troca de modalidade, visto que poderá utilizar o saldo para ambas.',
    },
  ],
  'Vale Transporte': [
    {
      question: 'Tem desconto?',
      answer:
        'O desconto é limitado 6% sobre o salário base, ou sobre o crédito, o valor que for menor. Exceto estagiários',
    },
    {
      question: 'É pago em dinheiro?',
      answer:
        'O benefício não será pago em dinheiro, a recarga será realizada diretamente no cartão desde o primeiro mês.',
    },
    {
      question: 'Quando o benefício estará disponível?',
      answer: 'No último dia útil do mês',
    },
    {
      question: 'Como faço a recarga?',
      answer:
        'A recarga é realizada automaticamente no cartão, porém, é necessário validar nos postos, Terminais e maquinas disponíveis tanto nos terminais de ônibus e ônibus da Sptrans, Metrô, CPTM e bancas de jornais. ',
    },
    {
      question: 'Eu já tenho um Bilhete Único, poderei usar o mesmo?',
      answer:
        'É possível utilizar o mesmo bilhete, desde que esteja cadastrado na SPtrans com seu CPF e seja o último cartão emitido',
    },
    {
      question: 'Como consulto o saldo?',
      answer: `Você pode consultar nos postos, Terminais e maquinas disponíveis tanto nos terminais de ônibus e ônibus da Sptrans, Metrô, CPTM e bancas de jornais. 
      Os usuários que possuem cartão SPTrans Bilhete Único (São Paulo, capital), Cartão BOM (intermunicipal de São Paulo) e RioCard (Rio de Janeiro) podem consultar através do Whatsapp (11) 3003-8737 `,
    },
    {
      question: 'Houve reajuste no valor da minha tarifa, o que devo fazer?',
      answer: 'Solicitar a alteração via RH CONECTA. O RH realizará a próxima recarga já com o valor corrigido.',
    },
    {
      question: 'Segunda via do cartão/bilhete, como que devo fazer?',
      answer: `Para RIO CARD é necessário abrir a solicitação no RH CONECTA.
      Para as demais operadoras, a solicitação deve ser feita pela central ou posto de atendimento.`,
    },
    {
      question: 'Quero cancelar ou alterar meu VT, como faço?',
      answer:
        'Através do RH CONECTA, até dia 10 de cada mês, a solicitação será programada para o mês seguinte. Solicitações após dia 10, serão consideradas para o mês subsequente',
    },
  ],
  'Assistência Médica': [
    {
      question: 'Tem desconto?',
      answer: 'Desconto apenas para dependentes',
    },
    {
      question: 'Fui admitido hoje, amanhã já poderei usar a assistência médica?',
      answer: 'O funcionário admitido, será incluído no plano no dia 01 do mês seguinte.',
    },
    {
      question: 'Existe carência?',
      answer: 'A inclusão do funcionário deve ocorrer até 30 dias após a admissão, para que entre sem carência',
    },
    {
      question: 'Qual é o Plano de Saúde oferecido pela empresa?',
      answer: 'De acordo com  a localidade e eligibilidade do colaborador',
    },
    {
      question: 'Posso incluir minha esposa(o) / filhos no plano?',
      answer:
        'Sim, a solicitação deve ser feita pelo RH CONECTA, ou na admissão. Para inclusão sem carência, deve ser solicitado até 30 dias da data do evento (Exemplos: Admissão, casamento, nascimento, adoção)',
    },
    {
      question: 'Como posso ter acesso a carteirinha digital?',
      answer: 'Disponível no App da operadora',
    },
    {
      question: 'Como tenho acesso a rede credenciada / Atendimento?',
      answer: 'Disponível no App da operadora',
    },
    {
      question: 'Tenho direito a reembolso?',
      answer:
        'Para análise do limite de reembolso é necessário solicitar a prévia para sua operadora de saúde, através do aplicativo ',
    },
    {
      question: 'Tem coparticipação? ',
      answer:
        'Coparticipação de 30% do valor do procedimento, ou limitado a: 20,00 consulta; 30,00 Pronto Socorro; 10,00 exames simples',
    },
    {
      question: 'Vou receber carteirinha fisica?',
      answer: 'Não, as carteirinhas são apenas digitais, você pode consulta-la através do app ou site de sua operadora',
    },
    {
      question: 'Fui demitido, serei excluido automaticamente do plano?',
      answer: 'Haverá permanencia até o ultimo dia do mês de demissão. ',
    },
    {
      question: 'Remover dependente no benefício.',
      answer: `
        <strong>Como excluir um dependente? </strong> </br>
        Enviar via RH Conecta com uma carta de solicitação de próprio punho com os dados do titular e do dependente que deseja exclusão. </br>
        <strong>Quanto tempo para excluir?</strong> </br>
        
        Caso a solicitação seja até o dia 10, o cancelamento acontece no último dia do mês solicitado. Se a solicitação for após o dia 10, o cancelamento acontece no último dia do mês subsequente.
      `,
    },
    {
      question: 'Consulta ou Reclamação referentes a assistência médica.',
      answer: `
        <strong>Fui promovido de Rol, como faço para mudar meu plano?</strong></br>
        O seu plano será alterado automaticamente no mês subsequente, desde que seja elegível.  Reforçando que, conforme politica de benefícios da empresa, a elegibilidade do plano de saúde está atrelado ao Rol e localidade de trabalho.</br>
        <strong>Posso solicitar upgrade de plano? </trong> </br>
        Não há possibilidade de upgrade, os planos são de acordo com a localidade e rol do profissional. </br>
        <strong>Como faço para receber a carteirinha do convênio?  </trong> </br>
        As operadoras não emitem mais carteirinha, você poderá visualiza-las através do APP.
      `,
    },
  ],
  'Assistência Odontologica': [
    {
      question: 'Tem desconto?',
      answer: 'Depende da região, necessário consultar através do RH CONECTA',
    },
    {
      question: 'Posso incluir minha esposa(o) / filhos?',
      answer:
        'Apenas dependentes diretos (conjuge, filhos até 21 anos, ou até 24 se for universitário e companheiro (a) com declaração pública marital)',
    },
    {
      question: 'Posso incluir agregados (pai, mãe, irmãos)?',
      answer: 'Não é permitido inclusão de agregados',
    },
    {
      question: 'Como tenho acesso a rede credenciada / Atendimento?',
      answer: 'Através do App de sua operadora',
    },
    {
      question: 'Fui demitido, serei excluido automaticamente do plano?',
      answer: 'Haverá permanencia até o último dia do mês de demissão.',
    },
    {
      question: 'Fui demitido, posso dar continuidade na Assistência Odontológia?',
      answer:
        'Apenas se o titular teve contribuição em folha referente a mensalidade. Apenas para casos de demissão sem justa causa.',
    },
  ],
  Mobilidade: [
    {
      question: 'Quem tem direito?',
      answer:
        'O benefício de MOBILIDADE é concedido ao colaborador que trabalhe em escala noturna com saída após a meia noite e conforme elegibilidade de cargos da empresa para utilização de compra de combustível para o deslocamento Residência x Trabalho.',
    },
    {
      question: 'Onde posso utilizar meu saldo Mobilidade?',
      answer:
        'Aplicativos de transporte particular, postos de gasolina, aluguel de carros, bicicletas e patinetes; Comprar vouchers/cupons para usar com nossos parceiros: ClickBus, E-Moving, Fretadão e outros relacionados; Pagamento de zona azul;Recarga de vale-transporte;Estacionamentos e outros relacionados;',
    },
  ],
  'Auxílio Creche': [
    {
      question: 'Como funciona?',
      answer: `
      O benefício de Auxílio Creche é concedido ao colaborador que possui o direito conforme determina o ACT/CCT. </br>
      O envio deve ser realizado até o dia 18, referente ao pagamento da mensalidade da competência atual;
      `,
    },
    {
      question: 'Qual documentação preciso enviar?',
      answer: 'Inserir todos os documentos por categoria',
    },
    {
      question: 'Quais os valores a serem reembolsados?',
      answer: `
        SINDADOS BA - Até 24 meses - até R$ 351,23 </br>
        SINDADOS BA - De 24 a 60 meses - até R$ 234,15 </br>
        SINDPD PE - De 0 a 7 anos - até R$ 300,00 </br>
        SINDPD RJ - Até 7 meses após o retorno da Licença maternidade - até R$ 230,00 </br>
        SINDPD SP - Até 24 meses - até R$ R$571,48 </br>
        SINDPD SP - De 24 a 60 meses - até R$ 500,05 </br>
        SINDPD CE - FORTALEZA- até 6 meses - ATÉ R$ 182,00 </br>
      `,
    },
    {
      question: 'Como solicitar?',
      answer: 'Abrir a solicitação pelo RH Conecta',
    },
    {
      question: 'Durante as férias não tenho acesso ao RH Conecta, como solicito o reembolso?',
      answer: 'Realizar a solicitação através do portal beneficios',
    },
  ],
  'Seguro de vida': [
    {
      question: 'Consulta ou Reclamação referentes ao seguro de vida.',
      answer: `
        <strong>Gostaria de saber qual é a regra do seguro de vida?</strong></br>
        Você precisa abrir o chamado, informando matrícula e nome completo. As informações sobre a apólice serão encaminhadas na sua totalidade.</br>
        <strong>Quais são as coberturas do seguro de vida?</strong></br>
        Você precisa abrir o chamado, informando matrícula e nome completo. As informações sobre a apólice serão encaminhadas na sua totalidade.
      `,
    },
    {
      question: 'Solicitar Certificado (Apólice).',
      answer: `
        <strong>Qual é o procedimento para obter o meu certificado do seguro de vida?</strong></br>
        Você precisa abrir o chamado, informando matrícula e nome completo. Em seguida, será encaminhado o certificado com todas as coberturas e prêmio.</br>
        <strong>Quais são as coberturas do seguro de vida?</strong></br>
        Você precisa abrir o chamado, informando matrícula e nome completo. As informações sobre a apólice serão encaminhadas na sua totalidade.
      `,
    },
    {
      question: 'Solicitar alteração de beneficiário do Seguro de Vida.',
      answer: `
        <strong>Como posso alterar os beneficiários do meu Seguro de Vida? </strong></br>
        Você deverá enviar o formulário aqui no Portal de benefícios para alteração.
      `,
    },
  ],
  'C4life POP – Programa de Orientação Pessoal': [
    {
      question: 'Do que se trata o programa?',
      answer: `O POP é um benefício de apoio e suporte a saúde emocional, por meio de 
      orientações de especialistas altamente qualificados, nos pilares psicológico, social, 
      financeiro, jurídico e orientação a tutores de pets.
      "A orientação é um processo de atendimento breve, para situações em que a pessoa 
      necessita de esclarecimento de dúvidas e/ou informações, bem como ações necessárias 
      para resolução de problemas do dia a dia que estejam tirando o seu equilíbrio emocional."`,
    },
    {
      question: 'Quais meios de contato?',
      answer: `
      Você pode acessar o Programa pelos caminhos: <br />
      Plataforma C4life – agendamento digital (<a href="http://app.c4life.com.br">http://app.c4life.com.br</a> ou 
      <a href="https://c4life.com.br/">https://c4life.com.br/</a>)<br />
      Ligação Telefônica - 0800 878 6548<br />
      WhatsApp - (11) 93934-7445 (exclusivo para mensagens de texto)<br />
      Importante: não há limite de utilização para orientações em qualquer um dos 
      pilares do Programa. <br />
      
      O horário de atendimento é das 8h às 18h, de segunda à sexta-feira (exceto feriados). Nos demais intervalos o canal de atendimento será exclusivo 
      pelo 0800 e somente em casos de urgências.<br />
      Para a orientação sobre Pets, o atendimento deverá ser agendado 
      via canal 0800 ou via mensagem de texto no WhatsApp.
      `,
    },
    {
      question: 'Como faço para receber a senha do primeiro acesso?',
      answer:
        'A senha para o primeiro acesso será enviado no seu e-mail corporativo em até 3 dias úteis a contar da sua data de admissão',
    },
    {
      question: 'O que posso solicitar de orientação?',
      answer: `
        Orientação Psicológica: Problemas pessoais (ansiedade, depressão, estresse), Incômodos no trabalho, Dificuldades de  relacionamento interpessoal, familiar. <br />

        Orientação Jurídica: Direito cívil geral, Direito imobiliário, Direito familiar, Direito do consumidor, Direito penal, Direito previdenciário. <br />
        Obs.: não inclui atendimento jurídico trabalhista <br />
        
        Orientação Financeira: Planejamento financeiro, Gestão de despesas <br />
        Obs.: não inclui gestão de investimenos <br />
        
        Orientação Social: Questões de  dependência química, Falecimento na família, Momentos de afastamento e aposentadoria. <br />
        
        Orientação sobre Pets: Vacinas, Cuidados com o filhote e com o animal geriátrico, Indicação de probióticos, Questões comportamentais, Orientação com Veterinário ou Adestrador.
        *Com horário agendado
      `,
    },
    {
      question: 'Como me inscrever?',
      answer: `
        Acesse a plataforma por meio do endereço: <a href="http://app.c4life.com.br">http://app.c4life.com.br</a> <br />
        ou acesse a plataforma por meio do nosso site: <a href="https://www.c4life.com.br">https://www.c4life.com.br</a> <br />
        1ª tela – entre com o seu login, que será o seu e-mail corporativo <br />
        2ª tela - Avaliar em qual das áreas busca-se uma orientação <br />
        3ª tela - Confirmação das informações do usuário e atualização se for necessário. <br />
        Como um dos contatos é feito via telefone, pedimos para que você atualize o seu 
        número e insira seu celular. Na sequência opte pela orientação ser feita por  telefone ou vídeo chamada. <br />
        4ª tela - Preenchimento do questionário com a principal questão a ser “foco” na orientação.  <br />
        5ª tela - Uma janela de agenda se abrirá para a escolha do melhor dia e horário  
        para você receber sua orientação. A disponibilidade dos horários será visível para 
        agendamento 24h úteis após o seu acesso. Clicar na escolha. <br />
        6ª tela - Confirmar as informações ou cancelar e retornar ao fluxo de agendamento. <br />
        7ª Você receberá um e-mail de confirmação do Agendamento de sua Orientação e 
        um lembrete será enviado algumas horas antes do horário da orientação agendada.
      `,
    },
    {
      question: 'Como me inscrever na Orientação sobre Pets?',
      answer:
        'Para ter uma orientação sobre seu Pet, utilize os canais WhatsApp 55 11 939347445 ou 0800 8786548 e uma especialista te atenderá para fazer esse agendamento.',
    },
    {
      question: 'Meu dependente pode utilizar o programa?',
      answer: `
        O programa também pode ser utilizado pelos Cônjuges e filhos até 24 anos. O cadastro desse ser feito pelo titular através do WhatsAPP  55 11 939347445. <br />
        Eles poderão acessar o programa pelo nosso canal 
        0800 8786548 ou pelo WhatsApp  (horário comercial, dias úteis).
      `,
    },
    {
      question: 'Agendei mas preciso cancelar, como fazer?',
      answer:
        'Na impossibilidade de receber o contato do especialista no horário e data agendados para a orientação, solicitamos que você faça o cancelamento na própria plataforma em até 2 horas anteriores ao horário agendado',
    },
  ],
};
