import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExtratoCobrancaResponse } from 'src/app/modules/pagamentos-e-debitos/models/ExtratoCobrancaResponse';
import { endpoints } from 'src/environments/endpoints';

@Injectable()
export class DebtLetterService {
  constructor(private http: HttpClient) {}

  getDischardDebtLetter(baseYear: string, baseMonth: string): Observable<ExtratoCobrancaResponse> {
    const url = endpoints.dischargeDebtLetter;
    const params = { baseYear, baseMonth };

    return this.http.get<ExtratoCobrancaResponse>(url, { params });
  }
}
