import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ListSimpleInvoice } from '../../models/ListSimpleInvoice';
import { SimpleInvoice } from '../../models/SimpleInvoice';
import { AuthService } from '../../services/auth';
import { InvoiceService } from '../../services/invoice';
import { LoaderService } from '../../services/loader';
import { NotificationService } from '../../services/notification';
import { saveByteArray } from '../../utils/helpers';

@Component({
  selector: 'app-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss'],
})
export class InvoiceTableComponent implements OnInit {
  listInvoice!: ListSimpleInvoice;

  dataSource = new MatTableDataSource<SimpleInvoice>();

  displayedColumns = ['product', 'amount', 'leftover', 'dueDate', 'interestAmount', 'yearMonth', 'dischargeDate'];

  constructor(
    private authService: AuthService,
    private router: Router,
    private loader: LoaderService,
    private invoiceService: InvoiceService,
    private notification: NotificationService
  ) {
    this.loadInvoicesResponse();
  }

  ngOnInit(): void {
    if (!this.listInvoice) {
      this.loadInvoices();
      return;
    }

    this.setInvoiceTable(this.listInvoice.invoices);
  }

  onInvoiceSelected(invoice: SimpleInvoice): void {
    if (invoice.dischargeDate) {
      return;
    }

    if (!this.listInvoice) {
      this.downloadInvoice(invoice);
      return;
    }

    this.notification
      .confirm({
        title: 'Deseja baixar </br> ou receber por e-mail?',
        confirmButtonText: 'Baixar',
        cancelButtonText: 'Receber por E-mail',

        cancelButtonColor: '#0093ff',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.downloadInvoice(invoice);
          return;
        }

        this.sendInvoiceByEmail(invoice);
      });
  }

  private downloadInvoice(invoice: SimpleInvoice): void {
    this.loader.show('Buscando boleto...');

    this.getRequest(invoice)
      .subscribe((r) => {
        saveByteArray(`boleto_${this.formatProtheusYearMonth(invoice.yearMonth)}.pdf`, r);
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private loadInvoicesResponse(): void {
    const currentNavigation = this.router.getCurrentNavigation();
    const navigationState = currentNavigation?.extras.state;

    this.listInvoice = navigationState?.listInvoice as ListSimpleInvoice;

    if (!this.listInvoice && !this.authService.isLogged) {
      this.router.navigate(['autenticacao']);
    }
  }

  private sendInvoiceByEmail(invoice: SimpleInvoice): void {
    this.invoiceService
      .sendInvoiceByEmail(invoice.idInvoice, this.listInvoice.idClient, this.listInvoice.branch)
      .subscribe(() => {
        this.notification.success('Foi enviado um e-mail para o endereço cadastrado.');
      });
  }

  private getRequest(invoice: SimpleInvoice): Observable<any> {
    if (this.listInvoice) {
      return this.invoiceService.publicDownloadInvoice(
        this.listInvoice.idClient,
        invoice.idInvoice,
        this.listInvoice.branch
      );
    }

    return this.invoiceService.downloadInvoice(invoice.idInvoice, invoice.idClient);
  }

  private loadInvoices(): void {
    this.loader.show('Buscando boletos...');
    this.invoiceService
      .getAllInvoices(true)
      .subscribe((invoices) => {
        this.setInvoiceTable(invoices);
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private formatProtheusYearMonth(date: string): string {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);

    return `${month}_${year}`;
  }

  private setInvoiceTable(invoices: SimpleInvoice[]): void {
    this.dataSource.data = invoices.sort((i, l) =>
      i.yearMonth < l.yearMonth ? 1 : i.yearMonth === l.yearMonth ? 0 : -1
    );
  }
}
