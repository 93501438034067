import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IncomeReport } from 'src/app/modules/pagamentos-e-debitos/models/IncomeReport';
import { AuthService } from '../../services/auth';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectEnterprise, selectUser } from '../../store/selectors';

@Component({
  selector: 'app-payment-report-table',
  templateUrl: './payment-report-table.component.html',
  styleUrls: ['./payment-report-table.component.scss'],
})
export class PaymentReportTableComponent {
  @Input() year!: string;

  paymentReport!: IncomeReport;

  cgc!: string;

  enterprise$ = this.store.select(selectEnterprise);

  user$ = this.store.select(selectUser);

  constructor(private store: Store<IAppState>, private router: Router, private authService: AuthService) {
    this.loadPaymentReport();
  }

  get nomeTitular(): string {
    return this.paymentReport?.families?.[0].beneficiaries?.[0].name ?? '';
  }

  @Input() set incomeReport(_incomeReport: IncomeReport) {
    this.paymentReport = _incomeReport;
  }

  printComponent() {
    window.print();
  }

  private loadPaymentReport(): void {
    const currentNavigation = this.router.getCurrentNavigation();
    const navigationState = currentNavigation?.extras.state;
    this.cgc = currentNavigation?.previousNavigation?.extractedUrl.queryParams.cpf as string;
    this.year = currentNavigation?.previousNavigation?.extractedUrl.queryParams.baseYear as string;
    this.paymentReport = navigationState?.paymentReport as IncomeReport;
    if (!this.cgc && !this.year) {
      this.cgc = navigationState?.cgc as string;
      this.year = navigationState?.baseYear as string;
    }

    if (!this.paymentReport && !this.authService.isLogged) {
      this.router.navigate(['autenticacao']);
    }
  }
}
