<mat-card *ngIf="mode === 'EditarPlano'" class="mt-4">
  <form [formGroup]="formGroup" #updatedPlanForm="ngForm">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Data de inicio do novo Plano</mat-label>
          <input type="text" matInput formControlName="dataAlteracao" mask="00/00/0000" placeholder="dd/mm/yyyy" />
          <mat-error *ngIf="formGroup.get('dataAlteracao')?.hasError('required')">Este campo é obrigatório</mat-error>
          <mat-error *ngIf="formGroup.get('dataAlteracao')?.hasError('dateBeforeToday')">
            Insira uma data posterior ou igual a hoje
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12 col-lg-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Carteirinha</mat-label>
          <input type="text" matInput formControlName="cardNumber" />
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-card>
<div class="beneficiary-plans-container">
  <app-beneficiary-plan-card
    *ngFor="let plan of plans; let i = index"
    [plan]="plan"
    [isSelected]="selectedCardIndex === i"
    (selectChanged)="onSelectedChanged(i)"
  ></app-beneficiary-plan-card>
</div>
<div class="mt-3">
  <button mat-stroked-button color="secondary" type="button" (click)="onBackClick()">Voltar</button>
  <button mat-raised-button color="accent" (click)="onFinishClick()" class="ms-2" [disabled]="!canSave">
    {{ mode === 'Adicionar' ? 'Continuar' : 'Salvar' }}
  </button>
</div>
