import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { Enterprise } from '../../models/Enterprise';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseService {

  //DEFAULT_SUBDOMAIN = 'alper';
  //DEFAULT_SUBDOMAIN = 'barela';
  //DEFAULT_SUBDOMAIN = 'blanchospital';
  //DEFAULT_SUBDOMAIN = 'divicom';
  //DEFAULT_SUBDOMAIN = 'gestao';
  //DEFAULT_SUBDOMAIN = 'healthadm';
  //DEFAULT_SUBDOMAIN = 'interbrasil';
  //DEFAULT_SUBDOMAIN = 'izii';
  //DEFAULT_SUBDOMAIN = 'minsait';
  //DEFAULT_SUBDOMAIN = 'nacionalsaude';
  //DEFAULT_SUBDOMAIN = 'tecsaude';
  //DEFAULT_SUBDOMAIN = 'uniben';
  //DEFAULT_SUBDOMAIN = 'voceclube';
  DEFAULT_SUBDOMAIN = 'wtw';
  //DEFAULT_SUBDOMAIN = 'wtwapresentacao';

  constructor(private http: HttpClient) {}

  getEnterprise(): Observable<Enterprise> {
    const subdomain = this.getSubdomain();
    const baseURL = endpoints.enterprise;
    const url = `${baseURL}/${subdomain}`;
    return this.http.get<Enterprise>(url);
  }

  getSubdomain(): string {
    const subdomain = environment.development ? this.DEFAULT_SUBDOMAIN : this.extractSubdomainFromURL(location.href);

    if (subdomain === 'portal-servicos-online') {
      return 'wtwapresentacao';
    }

    return subdomain;
  }

  private extractSubdomainFromURL(url: string): string {
    return url?.replace('https://', '').replace('http://', '')?.split('.')?.[0] ?? '';
  }
}
