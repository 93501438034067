const baseURL = 'https://dev-portal-cliente.azurewebsites.net';

const apiVersion = 'v1';
const url = `${baseURL}/api/${apiVersion}`;

export const environment = {
  production: false,
  development: false,

  url,
};
