import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { BeneficiaryHealthPlan } from 'src/app/core/models/BeneficiaryHealthPlan';
import { ConfigService } from 'src/app/core/services/config';
import { endpoints } from 'src/environments/endpoints';
import { BeneficiaryPlan } from '../../models/BeneficiaryPlan';
import { BeneficiaryType } from '../../types/BeneficiaryType';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  private http = inject(HttpClient);
  private config = inject(ConfigService);

  getAvailablePlans(
    beneficiaryType: BeneficiaryType,
    beneficiaryId?: string,
    beneficiaryBirthDate?: string,
    ibgeCityCode?: string,
    beneficiaryHealthPlan?: BeneficiaryHealthPlan
  ): Observable<BeneficiaryPlan[]> {
    const baseURL = endpoints.plans;
    const url = `${baseURL}`;

    let params = new HttpParams();
    params = params.set('beneficiaryType', String(beneficiaryType)[0]);

    if (beneficiaryId) {
      params = params.set('beneficiaryId', beneficiaryId);
    }

    if (beneficiaryBirthDate) {
      params = params.set('beneficiaryBirthDate', beneficiaryBirthDate);
    }

    if (this.config.getConfiguration('SHOULD_SEND_IBGE_CODE_ON_GET_PLANS')) {
      params = params.set('codigomunicipio', ibgeCityCode ?? '');
    }

    if (beneficiaryHealthPlan?.codeEntity) {
      params = params.set('codigoentidade', beneficiaryHealthPlan?.codeEntity);
    }

    if (beneficiaryHealthPlan?.contract) {
      params = params.set('codigocontrato', beneficiaryHealthPlan?.contract);
    }

    if (beneficiaryHealthPlan?.subContract) {
      params = params.set('subcontrato', beneficiaryHealthPlan?.subContract);
    }

    return this.http.get<BeneficiaryPlan[]>(url, { params });
  }
}
