export interface IUpdateClient {
  idClient: string;
  cep: string;
  addressNumber: string;
  address: string;
  dddHomePhone: string;
  homePhone: string;
  dddCell: string;
  cellNumber: string;
  addressComplement: string;
  uf: string;
  district: string;
  city: string;
  email: string;
}

export class UpdateClient {
  idClient: string;
  cep: string;
  addressNumber: string;
  address: string;
  dddHomePhone: string;
  homePhone: string;
  dddCell: string;
  cellNumber: string;
  addressComplement: string;
  uf: string;
  district: string;
  city: string;
  email: string;

  constructor(data: IUpdateClient) {
    this.idClient = data.idClient;
    this.cep = data.cep;
    this.addressNumber = data.addressNumber;
    this.address = data.address;
    this.dddHomePhone = data.dddHomePhone;
    this.homePhone = data.homePhone;
    this.dddCell = data.dddCell;
    this.cellNumber = data.cellNumber;
    this.addressComplement = data.addressComplement;
    this.uf = data.uf;
    this.district = data.district;
    this.city = data.city;
    this.email = data.email;
  }
}
