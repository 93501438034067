export interface Banner {
  id: number;
  imageUrl: string;
  linkTo: string;
  enterpriseName: string;
  titulo: string;
  descricao: string;
  tipo: BannerType;
  dataCriacao: string;
  ordem: number;
}

export interface CreateBannerDto {
  imageUrl: string;
  linkTo: string;
  idClient: string;
  titulo: string;
  descricao: string;
  tipo: BannerType;
  ordem: number;
}

export enum BannerType {
  Carousel = 0,
  Card = 1,
  QuickLink = 2,
  Todos = 99,
}
