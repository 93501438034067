export interface ISignInCredentials {
  cgc: string;
  password: string;
  tenantId: string;
}

export class SignInCredentials {
  cgc: string;
  password: string;
  tenantId: string;

  constructor(data: ISignInCredentials) {
    this.cgc = data.cgc;
    this.password = data.password;
    this.tenantId = data.tenantId;
  }
}
