import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { ImmediateCancellationRequest } from '../../models/ImmediateCancellationRequest';
import { ImmediateCancellationResponse } from '../../models/ImmediateCancellationResponse';

@Injectable()
export class ImmediateCancellationService {
  constructor(private http: HttpClient) {}

  doImmediateCancellation(payload: ImmediateCancellationRequest): Observable<ImmediateCancellationResponse> {
    const url = endpoints.immediateCancellation;

    return this.http.post<ImmediateCancellationResponse>(url, payload);
  }
}
