import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { environment } from 'src/environments/environment';
import { BeneficiaryDps, DpsQuestion } from '../../models/DpsQuestions';
import { DpsQuestionsService } from '../../services/dpsQuestions';

@Component({
  selector: 'app-beneficiary-health-statement',
  templateUrl: './beneficiary-health-statement.component.html',
  styleUrls: ['./beneficiary-health-statement.component.scss'],
})
export class BeneficiaryHealthStatementComponent implements OnInit {
  @Output() canceled = new EventEmitter();
  @Output() saved = new EventEmitter<BeneficiaryDps>();

  weightMask!: string;

  formGroup!: FormGroup;

  dpsQuestions!: DpsQuestion[];

  constructor(
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private dpsQuestionsService: DpsQuestionsService,
    private loader: LoaderService,
    private notification: NotificationService
  ) {}

  get questionsForm(): FormArray {
    return this.formGroup.get('questions') as FormArray;
  }

  ngOnInit(): void {
    this.loadDPSQuestions();
  }

  setWeightMask(): void {
    const inputValue = this.formGroup.get('weight')?.value;

    if (inputValue.length <= 2) {
      this.weightMask = '0.99';
    }

    if (inputValue.length === 3) {
      this.weightMask = '00.09';
    }

    if (inputValue.length > 3) {
      this.weightMask = '000.0';
    }

    this.cd.detectChanges();
  }

  onBackClick(): void {
    this.canceled.emit();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Preencha os campos corretamente');
      return;
    }

    const answeredDpsQuestions = this.getAnsweredDpsQuestions();
    this.saved.emit(answeredDpsQuestions);
  }

  isRowYes(index: number): boolean {
    const answerValue = this.questionsForm.at(index).get('answer')?.value;

    return answerValue === 'S';
  }

  private getAnsweredDpsQuestions(): BeneficiaryDps {
    const questions = this.questionsForm.getRawValue() as any[];
    return {
      weight: this.formGroup.get('weight')?.value,
      height: this.formGroup.get('height')?.value,
      questions: questions.map((formRow) => ({
        codigo: formRow.code,
        descricao: formRow.description,
        observacao: formRow.observation,
        valor: formRow.answer,
        dataEvento: formRow.dataEvento,
      })),
    };
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      weight: [null, Validators.required],
      height: [null, Validators.required],
      questions: this.formBuilder.array(
        this.dpsQuestions.map((question) =>
          this.formBuilder.group({
            code: [question.codigo, Validators.required],
            description: [question.descricao, Validators.required],
            answer: [environment.development ? 'N' : question.valor, Validators.required],
            observation: [null],
            dataEvento: [null],
          })
        )
      ),
    });

    this.setObservationValidation();
  }

  private setObservationValidation(): void {
    const questions = this.formGroup.get('questions') as FormArray;

    questions.controls?.forEach((formGroup) => {
      formGroup.get('answer')?.valueChanges.subscribe((v) => {
        const isYes = v === 'S';
        const observation = formGroup.get('observation');
        const dataEvento = formGroup.get('dataEvento');
        const hasObservation = !!observation?.value;

        if (isYes && !hasObservation) {
          observation?.setValidators(Validators.required);
          dataEvento?.setValidators(Validators.required);
          observation?.setErrors({ required: true });
          dataEvento?.setErrors({ required: true });
          return;
        }
        observation?.clearValidators();
        dataEvento?.clearValidators();
        observation?.setErrors(null);
        dataEvento?.setErrors(null);
      });
    });
  }

  private loadDPSQuestions(): void {
    this.loader.show('Buscando declaração de saúde...');

    this.dpsQuestionsService
      .getAll()
      .subscribe((questions) => {
        this.dpsQuestions = questions;
        this.buildForm();
      })
      .add(() => {
        this.loader.hide();
      });
  }
}
