import { createSelector } from '@ngrx/store';
import packageJsonInfo from '../../../../package.json';
import { UserType } from '../models/UserType';
import { IAppState } from './interfaces/IAppState';
import { ICoreState } from './interfaces/ICoreState';

const selectCoreState = (state: IAppState) => {
  return state.core;
};

export const selectEnterprise = createSelector(selectCoreState, (state: ICoreState) => {
  return state.enterprise;
});

export const selectTenantNome = createSelector(selectCoreState, (state: ICoreState) => {
  return state.enterprise?.tenantNome;
});

export const selectEnterpriseEmailDomain = createSelector(selectCoreState, (state: ICoreState) => {
  return state.enterprise?.email.split('@')[1];
});

export const selectTenantId = createSelector(selectCoreState, (state: ICoreState) => {
  const tenantClient = state.enterprise?.tenantProtheus;
  let tenantBranch = state.user?.branch;

  if (state.enterprise?.tenantNome === 'blanchospital') {
    tenantBranch = '09';
  }

  return tenantBranch ? `${tenantClient},${tenantBranch}` : tenantClient;
});

export const selectTenantIdWithoutBranch = createSelector(selectCoreState, (state: ICoreState) => {
  return state.enterprise?.tenantProtheus;
});

export const selectUser = createSelector(selectCoreState, (state: ICoreState) => {
  return state.user;
});

export const selectUserType = createSelector(selectCoreState, (state: ICoreState) => {
  if (!state.user) {
    return null;
  }

  return state.user.cgc.length === 11 ? 'PF' : ('PJ' as UserType);
});

export const selectToken = createSelector(selectCoreState, (state: ICoreState) => {
  return state.token?.token;
});

export const selectIsLogged = createSelector(selectCoreState, (state: ICoreState) => {
  return !!state.user;
});

export const selectSidenavOpened = createSelector(selectCoreState, (state: ICoreState) => {
  return state.sidenavOpened;
});

export const selectConsentForms = createSelector(selectCoreState, (state: ICoreState) => {
  return state.consentForms;
});

export const selectAppVersion = createSelector(selectCoreState, (state: ICoreState) => {
  return state.appVersion;
});

export const selectIsAppVersionUpdated = createSelector(selectCoreState, (state: ICoreState) => {
  return state.appVersion === packageJsonInfo.version;
});

export const selectHasEnterprise = createSelector(selectCoreState, (state) => {
  return !!state.enterprise;
});

export const selectIdClient = createSelector(selectCoreState, (state) => state.user?.idClient);

export const selectIsCookieAccepted = createSelector(selectCoreState, (state) => state.isCookieAccepted);
