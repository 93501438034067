import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IpService {
  constructor() {}

  getUserIp(): Observable<string> {
    return from(this.getAsyncUserIP());
  }

  private async getAsyncUserIP(): Promise<string> {
    const url = 'https://jsonip.com';
    const response = await fetch(url, { mode: 'cors' });
    const json: { ip: string } = await response.json();
    return json.ip;
  }
}
