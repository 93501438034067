import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExtratoRoutingModule } from './extrato-routing.module';
import { BuscaExtratoCopartComponent } from './components/busca-extrato-copart';
import { ListaExtratoCopartComponent } from './components/lista-extrato-copart';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { ModalBuscaExtratoCopartComponent } from './components/modal-busca-extrato-copart/modal-busca-extrato-copart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';
import { MatButtonModule } from '@angular/material/button';
import { CoparticipationService } from './services/coparticipation';
import { DebtLetterService } from './services/debt-letter';
import { MatSelectModule } from '@angular/material/select';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ExtratoCobrancaComponent } from './components/extrato-cobranca/extrato-cobranca.component';
import { BuscaExtratoCobrancaComponent } from './components/busca-extrato-cobranca';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    BuscaExtratoCopartComponent,
    BuscaExtratoCobrancaComponent,
    ListaExtratoCopartComponent,
    ModalBuscaExtratoCopartComponent,
    ExtratoCobrancaComponent,
  ],
  imports: [
    CommonModule,
    ExtratoRoutingModule,
    ComponentsModule,
    MatTableModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,

    PipesModule,

    NgxMaskModule.forRoot(),
  ],

  providers: [CoparticipationService, DebtLetterService],
})
export class ExtratoModule {}
