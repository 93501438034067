<form class="auth-container" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h5 class="w-100 mb-3">Insira suas informações</h5>

  <ng-container *ngIf="config.getConfiguration('FIRST_ACCESS_CODE_FIELD') as codeField">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>CPF/CNPJ</mat-label>
      <input type="text" matInput formControlName="document" mask="CPF_CNPJ" />
      <mat-error *ngIf="formGroup.get('document')?.hasError('required')">Este campo é obrigatório</mat-error>
      <mat-error *ngIf="formGroup.get('document')?.hasError('mask')">Digite o CNPJ corretamente</mat-error>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="w-100"
      *ngIf="codeField === 'DATA_NASC' && isCpf; else webValidationInput"
    >
      <mat-label>Data de Nascimento</mat-label>
      <input type="text" matInput formControlName="webValidationNumber" mask="00/00/0000" />
      <mat-error>Este campo é obrigatório</mat-error>
    </mat-form-field>

    <ng-template #webValidationInput>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Número de Validação Web</mat-label>
        <input type="text" matInput formControlName="webValidationNumber" mask="9999999999" />
        <mat-error>Este campo é obrigatório</mat-error>
      </mat-form-field>
    </ng-template>
  </ng-container>

  <div class="links-container">
    <span routerLink="/">Voltar</span>
  </div>

  <button mat-raised-button color="accent" type="submit">ENTRAR</button>
</form>
