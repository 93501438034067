import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { Beneficiary } from 'src/app/core/models/Beneficiary';
import { BeneficiaryHealthPlan } from 'src/app/core/models/BeneficiaryHealthPlan';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { CustomValidators } from 'src/app/core/utils/custom-validators';
import { formatDate } from 'src/app/core/utils/helpers';
import { BeneficiaryPlan } from '../../models/BeneficiaryPlan';
import { PlanService } from '../../services/plan';
import { BeneficiaryType } from '../../types/BeneficiaryType';
@Component({
  selector: 'app-beneficiary-plans',
  templateUrl: './beneficiary-plans.component.html',
  styleUrls: ['./beneficiary-plans.component.scss'],
})
export class BeneficiaryPlansComponent implements OnInit {
  @Input() beneficiaryId!: string;
  @Input() beneficiaryParent!: Beneficiary;
  @Input() type!: BeneficiaryType;
  @Input() beneficiaryFormValue!: Beneficiary;

  @Output() finished = new EventEmitter();
  @Output() canceled = new EventEmitter();

  plans!: BeneficiaryPlan[];
  selectedCardIndex: null | number = null;
  @Input() mode!: 'Adicionar' | 'Editar' | 'EditarPlano';
  formGroup = this.buildForm();
  @ViewChild('updatedPlanForm') ngForm!: NgForm;
  canSave = true;
  constructor(
    private planService: PlanService,
    private loader: LoaderService,
    private beneficiaryService: BeneficiaryService,
    private formBuilder: FormBuilder,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadPlans();
    this.buildForm();
  }

  onSelectedChanged(index: number): void {
    this.selectedCardIndex = index;
  }

  onFinishClick(): void {
    if (this.mode === 'EditarPlano') {
      const selectedPlan = this.onSubmitUpdatedPlan();
      if (selectedPlan) {
        this.finished.emit(this.getSelectedPlan());
        return;
      }
    }

    if (this.getSelectedPlan()) {
      this.finished.emit(this.getSelectedPlan());
    }
  }

  onBackClick(): void {
    this.canceled.emit();
  }

  private loadPlans(): void {
    if (this.mode === 'EditarPlano') {
      this.beneficiaryService.findBeneficiaryById(this.beneficiaryId).subscribe((res) => {
        const beneficiary = res as Beneficiary;
        let beneficiaryParam = this.type == 'Titular' ? beneficiary : this.beneficiaryParent;
        this.getAvaiblePlans(
          this.type,
          beneficiary.idBeneficiary as string,
          formatDate(beneficiary.birthDate, true, ''),
          beneficiaryParam?.ibgeCityCode,
          beneficiaryParam.healthPlan
        );
      });
      return;
    }

    let beneficiaryParam = this.type == 'Titular' ? this.beneficiaryFormValue : this.beneficiaryParent;

    this.getAvaiblePlans(
      this.type,
      this.beneficiaryId,
      this.beneficiaryFormValue.birthDate,
      beneficiaryParam?.ibgeCityCode,
      beneficiaryParam?.healthPlan
    );
  }

  private getAvaiblePlans(
    type: BeneficiaryType,
    beneficiaryId: string,
    birthDate: string,
    ibgeCityCode: string | undefined,
    healthPlan?: BeneficiaryHealthPlan
  ) {
    this.loader.show('Carregando planos disponíveis...');
    this.planService
      .getAvailablePlans(type, beneficiaryId, birthDate, ibgeCityCode, healthPlan)
      .subscribe((plans) => {
        this.plans = plans;
        if (this.plans) {
          if (healthPlan?.ans) {
            let currentPlan = this.plans.find((p) => p.ansCode == healthPlan?.ans);

            if (currentPlan && this.mode != 'Adicionar') {
              currentPlan.isCurrentPlan = true;
            }
            const otherPlans = plans.filter((p) => p.ansCode !== healthPlan?.ans);
            if (otherPlans.length == 0 && this.mode != 'Adicionar') {
              this.notification.info('Não existem planos diferentes do atual disponíveis.');
              this.canSave = false;
            }
          }
        }
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private getSelectedPlan(): BeneficiaryPlan | null {
    if (this.selectedCardIndex == null) {
      this.notification.info('Selecione um plano.');
      return null;
    }

    return this.plans[this.selectedCardIndex];
  }

  private buildForm() {
    const formGroup = this.formBuilder.nonNullable.group({
      dataAlteracao: ['', [Validators.required, CustomValidators.dateTodayOrAfter('ddMMyyyy')]],
      cardNumber: [null],
    });

    return formGroup;
  }

  onSubmitUpdatedPlan(): BeneficiaryPlan | null {
    const fakeSubmitEvent = new Event('submit');
    this.ngForm.onSubmit(fakeSubmitEvent);
    if (this.formGroup.invalid) {
      this.notification.info('Preencha os campos corretamente');
      return null;
    }
    const formValue = this.formGroup.getRawValue();
    let selectecPlan = this.getSelectedPlan();
    if (selectecPlan) {
      selectecPlan.idBeneficiary = this.beneficiaryId;
      selectecPlan.updatedDate = formValue.dataAlteracao;
      selectecPlan.cardNumber = formValue?.cardNumber;
      return selectecPlan;
    } else {
      this.notification.info('Selecione um plano');
    }

    return null;
  }
}
