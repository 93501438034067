import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SegundaViaRoutingModule } from './segunda-via-routing.module';
import { SegundaViaBoletoComponent } from './components/segunda-via-boleto';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { NgxMaskModule } from 'ngx-mask';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [SegundaViaBoletoComponent],
  imports: [
    CommonModule,
    SegundaViaRoutingModule,
    ComponentsModule,
    MatTableModule,
    MatCardModule,
    NgxMaskModule.forRoot(),

    PipesModule,
  ],
})
export class SegundaViaModule {}
