import { Component, Input, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RecoveryPassRequest } from 'src/app/core/models/RecoveryPassRequest';
import { AuthService } from 'src/app/core/services/auth';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  formGroup!: FormGroup;
  token!: string;

  constructor(
    private router: Router,
    private loader: LoaderService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.loadParams();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Preencha os campos corretamente');
      return;
    }
    this.loader.show('Alterando senha');
    this.authService
      .recoveryPassword(this.getRecoveryPassRequest())
      .subscribe(() => {
        this.notification.success('Senha alterada com sucesso');
        this.router.navigate(['autenticacao']);
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private loadParams(): void {
    this.activatedRoute.queryParams.subscribe(({ t }) => {
      this.token = t;

      if (!t) {
        this.router.navigate(['autenticacao']);
      }
    });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordConfirm: [''],
    });
    this.formGroup
      .get('passwordConfirm')
      ?.setValidators([Validators.required, this.passwordValidator, Validators.minLength(8)]);
  }

  private passwordValidator = () => {
    const formValue = this.formGroup.getRawValue();
    const isValid = formValue.password === formValue.passwordConfirm;
    return isValid ? null : { invalid: true };
  };

  private getRecoveryPassRequest(): RecoveryPassRequest {
    const formValue = this.formGroup.getRawValue();
    return new RecoveryPassRequest({
      password: formValue.password,
      passwordConfirm: formValue.passwordConfirm,
      token: this.token,
    });
  }
}
