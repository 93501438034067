import { Component, OnInit } from '@angular/core';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';

import packageJsonInfo from '../../../../../package.json';
import { ConfigService } from '../../services/config';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectEnterprise } from '../../store/selectors';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  faPhoneAlt = faPhoneAlt;
  enterprise$ = this.store.select(selectEnterprise);
  version = packageJsonInfo.version;
  route_seguranca!: string;
  route_privacidade!: string;
  isWhitePhoneNumber: boolean = false;
  constructor(private store: Store<IAppState>, private config: ConfigService) {}
  ngOnInit(): void {
    this.route_seguranca = this.config.getConfiguration('SEGURANCA_INFORMACAO_ROUTE');
    this.route_privacidade = this.config.getConfiguration('PRIVACIDADE_ROUTE');
    this.isWhitePhoneNumber = this.config.getConfiguration('SHOUD_PHONE_FOOTER_BE_WHITE');
  }
}
