<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Extrato de coparticipação', 'extrato/copart'],
    ['Resultado', '']
  ]"
></app-breadcrumb>

<mat-card class="mt-4">
  <div class="d-flex justify-content-between">
    <h4 class="mb-4">Extrato de coparticipação do beneficiário</h4>
    <button mat-raised-button color="primary" (click)="print()">
      <mat-icon>print</mat-icon>
    </button>
  </div>

  <p class="mb-0" *ngIf="extratoCopart">
    <span class="fw-bold">Nome do beneficiário</span>
    : {{ extratoCopart.name }}
  </p>

  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Valor</th>
      <td mat-cell *matCellDef="let copartItem">{{ copartItem.amount | currency: 'BRL' }}</td>
    </ng-container>

    <ng-container matColumnDef="billingDate">
      <th mat-header-cell *matHeaderCellDef>Ano/Mês</th>
      <td mat-cell *matCellDef="let copartItem">{{ copartItem.billingYear }}/{{ copartItem.billingMonth }}</td>
    </ng-container>

    <ng-container matColumnDef="procedure">
      <th mat-header-cell *matHeaderCellDef>Procedimento</th>
      <td mat-cell *matCellDef="let copartItem">{{ copartItem.procedure ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="provider">
      <th mat-header-cell *matHeaderCellDef>Provedor</th>
      <td mat-cell *matCellDef="let copartItem">{{ copartItem.provider ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Quantidade</th>
      <td mat-cell *matCellDef="let copartItem">{{ copartItem.quantity }}</td>
    </ng-container>

    <ng-container matColumnDef="serviceDate">
      <th mat-header-cell *matHeaderCellDef>Data de atendimento</th>
      <td mat-cell *matCellDef="let copartItem">{{ copartItem.serviceDate | dashToBar }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let copartItem">{{ copartItem.name }}</td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" colspan="9999" *ngIf="loaded">Nenhum dado encontrado</td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let copartItem; columns: displayedColumns"></tr>
  </table>
</mat-card>
