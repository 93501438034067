import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { ExtratoCopartResponse } from '../../models/ExtratoCopartResponse';

@Injectable()
export class CoparticipationService {
  constructor(private http: HttpClient) {}

  getCopart(params: Params): Observable<ExtratoCopartResponse> {
    const url = endpoints.coparticipation;

    return this.http.get<ExtratoCopartResponse>(url, { params });
  }
}
