<mat-drawer-container class="sidenav-container">
  <mat-drawer
    [autoFocus]="false"
    [mode]="(smallScreen$ | async) ? 'over' : 'side'"
    [opened]="sidenavOpened$ | async"
    *ngIf="isLoggedIn$ | async"
    #drawer
  >
    <div class="profile-actions" [matMenuTriggerFor]="menu">
      <div class="user-informations">
        <div class="user-name" [title]="(loggedUser$ | async)?.nome">
          {{ (loggedUser$ | async)?.nome }}
        </div>
      </div>

      <button mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <mat-menu #menu="matMenu" xPosition="before">
      <a mat-menu-item routerLink="/dados-cadastrais/dados-de-cobranca">
        <div class="d-flex align-items-center">
          <fa-icon [icon]="fontAwersome.faPen"></fa-icon>
          <div class="ms-2">{{ 'DADOS_CADASTRAIS' | configLabel }}</div>
        </div>
      </a>

      <a mat-menu-item routerLink="/perfil/alterar-senha">
        <div class="d-flex align-items-center">
          <fa-icon [icon]="fontAwersome.faLock"></fa-icon>
          <div class="ms-2">Alterar senha</div>
        </div>
      </a>
    </mat-menu>

    <hr />

    <ng-container *ngFor="let item of menuItems$ | async">
      <button mat-button class="menu-button" (click)="toggleActivatedMenu(item)">
        <a class="menu-item" [routerLink]="item.url" *ngIf="!item.onClick">
          <fa-icon [icon]="item.faIcon"></fa-icon>
          <p class="ms-2 my-0">{{ item.label }}</p>
          <fa-icon
            [icon]="fontAwersome.faChevronDown"
            class="more-icon"
            *ngIf="item.children?.length"
            [class.activated]="isMenuActivated(item)"
          ></fa-icon>
        </a>

        <span class="menu-item" *ngIf="item.onClick">
          <fa-icon [icon]="item.faIcon"></fa-icon>
          <p class="ms-2 my-0">{{ item.label }}</p>
          <fa-icon
            [icon]="fontAwersome.faChevronDown"
            class="more-icon"
            *ngIf="item.children?.length"
            [class.activated]="isMenuActivated(item)"
          ></fa-icon>
        </span>
      </button>
      <div
        class="menu-children"
        *ngIf="item.children?.length"
        [class.inactivated]="!isMenuActivated(item)"
        [ngStyle]="getItemStyle(item)"
      >
        <ng-container *ngFor="let children of item.children">
          <a
            mat-button
            class="menu-button"
            [routerLink]="children.url"
            (click)="clearActivatedMenu()"
            *ngIf="!children.onClick"
          >
            <div class="menu-item">
              <p class="ms-2 my-0">{{ children.label }}</p>
            </div>
          </a>

          <button
            type="button"
            mat-button
            class="menu-button"
            (click)="children.onClick(); clearActivatedMenu()"
            *ngIf="children.onClick"
          >
            <div class="menu-item">
              <p class="ms-2 my-0">{{ children.label }}</p>
            </div>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </mat-drawer>
  <mat-drawer-content>
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
