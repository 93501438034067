<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Extrato de cobrança', '']
  ]"
></app-breadcrumb>

<mat-card class="mt-4">
  <h4>Extrato de cobrança</h4>
  <p class="text-muted">Selecione o boleto</p>

  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef>Plano</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.product }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Valor</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.amount | currency : 'BRL' }}</td>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef>Data de Vencimento</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.dueDate | protheusDate }}</td>
    </ng-container>

    <ng-container matColumnDef="interestAmount">
      <th mat-header-cell *matHeaderCellDef>Montante de juros</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.interestAmount | currency : 'BRL' }}</td>
    </ng-container>

    <ng-container matColumnDef="yearMonth">
      <th mat-header-cell *matHeaderCellDef>Ano/Mês</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.yearMonth | mask : '0000/00' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let invoice; columns: displayedColumns"
      class="c-pointer"
      (click)="onInvoiceSelected(invoice)"
    ></tr>
  </table>
</mat-card>
