import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DefaultErrorResponse } from '../../models/DefaultErrorResponse';
import { NotificationService } from '../../services/notification';
import { IAppState } from '../../store/interfaces/IAppState';
import { Store } from '@ngrx/store';
import { signOut } from '../../store/actions';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private notification: NotificationService, private store: Store<IAppState>) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((e) => this.handleError(e)));
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    const error = this.extractError(errorResponse);

    this.emitErrorNotification(error);

    return throwError(error);
  }

  private emitErrorNotification(error: DefaultErrorResponse): void {
    const DEFAULT_404_ERROR_TEXT = 'Nenhum resultado encontrado';

    if (error.statusCode === HttpStatusCode.Unauthorized) {
      this.store.dispatch(signOut());

      this.notification.info('Você não está autenticado');
      return;
    }

    this.notification.info(error.message);
    error.details
      .filter(Boolean)
      .filter((v) => v !== DEFAULT_404_ERROR_TEXT)
      .forEach((e) => this.notification.info(e));
  }

  private extractError(errorResponse: HttpErrorResponse): DefaultErrorResponse {
    const error = errorResponse.error;
    const statusCode = errorResponse.status;
    let message = error?.message;
    let details = error?.details;

    if (error instanceof ArrayBuffer) {
      const objError = this.arrayBufferToJson(error);
      message = objError?.message ?? 'Não foi possível baixar o arquivo.';
      details = [];
    }

    return new DefaultErrorResponse({ statusCode, message, details });
  }

  private arrayBufferToJson(buffer: ArrayBuffer): any {
    try {
      return JSON.parse(new TextDecoder().decode(buffer));
    } catch {
      return null;
    }
  }
}
