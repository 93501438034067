import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { endpoints } from 'src/environments/endpoints';
import { ExtratoCobrancaResponse } from '../../models/ExtratoCobrancaResponse';

@Injectable({
  providedIn: 'root',
})
export class PagamentosEDebitosService {
  constructor(private http: HttpClient) {}

  getAnnualStatement(baseYear: number): Observable<ExtratoCobrancaResponse> {
    const url = endpoints.dischargeDebtLetter;
    const params = { baseYear };

    return this.http.get<ExtratoCobrancaResponse>(url, { params });
  }

  getDischardDebtLetter(baseYear: number, baseMonth: number): Observable<ExtratoCobrancaResponse> {
    const url = endpoints.dischargeDebtLetter;
    const params = { baseYear, baseMonth };

    return this.http.get<ExtratoCobrancaResponse>(url, { params });
  }
}
