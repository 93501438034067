import { createAction, props } from '@ngrx/store';
import { ConsentForm } from '../models/ConsentForm';
import { DefaultErrorResponse } from '../models/DefaultErrorResponse';
import { Enterprise } from '../models/Enterprise';
import { SignInCredentials } from '../models/SignInCredentials';
import { SignInResponse } from '../models/SignInResponse';
import { User } from '../models/User';

export enum CoreActions {
  SetEnterprise = '[Core] Set Enterprise',
  SetUser = '[Core] Set User',
  SetConsentForms = '[Core] Set ConsentForms',
  SetAuthData = '[Core] Set Auth Data',
  SignInAttempt = '[Core] Sign In Attempt',
  SignInError = '[Core] Sign In Error',
  SignOut = '[Core] Sign Out',
  RedirectIfLoggedIn = '[Core] Redirect If Logged In',
  LoadEnterprise = '[Core] Load Enterprise',
  LoadConsentForms = '[Core] Load ConsentForms',
  ToggleSidenav = '[Core] Toggle Sidenav',
  OpenSidenav = '[Core] Open Sidenav',
  CloseSidenav = '[Core] Close Sidenav',
  SetAppVersion = '[Core] Set App Version',
  SetEnterpriseCssColors = '[Core] Set Enterprise CSS Colors',
  ShowCookieAcceptance = '[Core] Show Cookie Acceptance',
  CookieAccepted = '[Core] Cookie Accepted',
  ShowPolicysAcceptance = '[Core] Show Policy Acceptance',
  PolicysAccepted = '[Core] Show Policy Accepted',
  IsPolicysAccepted = '[Core] Show IsPolicy Accepted',
}

export const setEnterprise = createAction(CoreActions.SetEnterprise, props<{ payload: Enterprise }>());

export const signInAttempt = createAction(CoreActions.SignInAttempt, props<{ payload: SignInCredentials }>());

export const signInError = createAction(CoreActions.SignInError, props<{ payload: DefaultErrorResponse }>());

export const setUser = createAction(CoreActions.SetUser, props<{ payload: User }>());

export const setConsentForms = createAction(CoreActions.SetConsentForms, props<{ payload: ConsentForm[] }>());

export const setAuthData = createAction(CoreActions.SetAuthData, props<{ payload: SignInResponse }>());

export const signOut = createAction(CoreActions.SignOut);

export const redirectIfLoggedIn = createAction(CoreActions.RedirectIfLoggedIn);

export const loadEnterprise = createAction(CoreActions.LoadEnterprise);
export const loadConsentForms = createAction(CoreActions.LoadConsentForms);

export const toggleSidenav = createAction(CoreActions.ToggleSidenav);

export const openSidenav = createAction(CoreActions.OpenSidenav);

export const closeSidenav = createAction(CoreActions.CloseSidenav);

export const setAppVersion = createAction(CoreActions.SetAppVersion, props<{ payload: string }>());

export const showCookieAcceptance = createAction(CoreActions.ShowCookieAcceptance);
export const showPolicysAcceptance = createAction(CoreActions.ShowPolicysAcceptance);

export const cookieAccepted = createAction(CoreActions.CookieAccepted);
export const policysAccepted = createAction(CoreActions.PolicysAccepted);
export const isPolicysAccepted = createAction(CoreActions.IsPolicysAccepted);
