<div class="bg-container">
  <h1 class="main-title">Resolva tudo on-line.</h1>
  <p class="main-subtitle" *ngIf="shouldShowSubtitle">
    Atualize seu cadastro, solicite 2ª via de boleto, carteirinha e muito mais!
  </p>
</div>

<div class="main-buttons-container" *ngIf="shouldShowButtons">
  <div class="main-button" routerLink="/">Login</div>
  <div class="main-button" routerLink="servicos-cpf">Serviços por CPF/CNPJ</div>
</div>

<router-outlet></router-outlet>
