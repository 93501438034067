import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth';
import { Store } from '@ngrx/store';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectEnterpriseEmailDomain } from 'src/app/core/store/selectors';

@Component({
  selector: 'app-recovery-pass',
  templateUrl: './recovery-pass.component.html',
  styleUrls: ['./recovery-pass.component.scss'],
})
export class RecoveryPassComponent implements OnInit {
  formGroup!: FormGroup;

  enterpriseEmailDomain$ = this.store.select(selectEnterpriseEmailDomain);

  constructor(
    private store: Store<IAppState>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loader: LoaderService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  onSubmit(): void {
    const cgc = this.getCgc();

    this.loader.show('Enviando solicitação...');
    this.authService
      .sendRecoveryPasswordEmail(cgc)
      .subscribe(() => {
        this.notification.success(
          'Caso o documento estiver cadastrado, enviaremos um e-mail de recuperação de senha para o endereço cadastrado.'
        );
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      cgc: [null, [Validators.required]],
    });
  }

  private getCgc(): string {
    return this.formGroup.get('cgc')?.value;
  }
}
