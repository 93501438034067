<div class="breadbrumb-container">
  <ng-container *ngFor="let step of steps; let i = index">
    <span *ngIf="i !== stepsAmount - 1">
      <a *ngIf="!step[2]" [routerLink]="extractRouterLink(step[1])">{{ step[0] }}</a>
      <a *ngIf="step[2]" [routerLink]="extractRouterLink(step[1])" [queryParams]="step[2]">{{ step[0] }}</a>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </span>
    <span *ngIf="i === stepsAmount - 1" class="text-muted">
      {{ step[0] }}
    </span>
  </ng-container>
</div>
