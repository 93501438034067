import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { map, Observable } from 'rxjs';

import { SignInCredentials } from 'src/app/core/models/SignInCredentials';
import { endpoints } from 'src/environments/endpoints';
import { RecoveryPassRequest } from '../../models/RecoveryPassRequest';
import { SignInResponse } from '../../models/SignInResponse';
import { User } from '../../models/User';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectIsLogged } from '../../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLogged = false;

  constructor(private http: HttpClient, private store: Store<IAppState>) {
    this.setIsLogged();
  }

  signIn(payload: SignInCredentials): Observable<SignInResponse> {
    const baseURL = endpoints.auth;
    const url = `${baseURL}/login`;

    return this.http.post<SignInResponse>(url, payload);
  }

  sendRecoveryPasswordEmail(cgc: string): Observable<void> {
    const baseURL = endpoints.auth;
    const url = `${baseURL}/sendRecoveryPasswordEmail`;

    return this.http.post<void>(url, { cgc });
  }

  validateRecoveryPassToken(token: string): Observable<void> {
    const baseURL = endpoints.auth;
    const url = `${baseURL}/validateRecoveryPassToken`;

    return this.http.post<void>(url, { token });
  }

  recoveryPassword(payload: RecoveryPassRequest): Observable<void> {
    const baseURL = endpoints.auth;
    const url = `${baseURL}/recoveryPassword`;

    return this.http.post<void>(url, payload);
  }

  confirmEmail(token: string): Observable<void> {
    const baseURL = endpoints.auth;
    const url = `${baseURL}/activateUser`;

    return this.http.post<void>(url, { token });
  }

  resendEmailConfirmation(cgc: string): Observable<string> {
    const baseURL = endpoints.users;
    const url = `${baseURL}/resendEmailConfirmation`;

    return this.http.post<{ email: string }>(url, { cgc }).pipe(map((response) => response.email));
  }

  getUserByToken(token: string): Observable<User> {
    const baseURL = endpoints.auth;
    const url = `${baseURL}/get-user-by-token`;

    return this.http.post<User>(url, { token });
  }

  private setIsLogged(): void {
    this.store.select(selectIsLogged).subscribe((isLogged) => {
      this.isLogged = isLogged;
    });
  }
}
