export interface IImmediateCancellationRequest {
  idClient: string;
  reasonCancellation: string;
  bank: string;
  agency: string;
  agencyDigit: string;
  account: string;
  accountDigit: string;
  accountType: string;
  cgc: string;
  name: string;
  email: string;
  password: string;
  beneficiaries: { selected: boolean; idBeneficiary: string }[];
}

export class ImmediateCancellationRequest {
  idClient: string;
  reasonCancellation: string;
  bank: string;
  agency: string;
  agencyDigit: string;
  account: string;
  accountDigit: string;
  accountType: string;
  cgc: string;
  name: string;
  email: string;
  password: string;
  beneficiaries: { selected: boolean; idBeneficiary: string }[];

  constructor(data: IImmediateCancellationRequest) {
    this.idClient = data.idClient;
    this.reasonCancellation = data.reasonCancellation;
    this.bank = data.bank;
    this.agency = data.agency;
    this.agencyDigit = data.agencyDigit;
    this.account = data.account;
    this.accountDigit = data.accountDigit;
    this.accountType = data.accountType;
    this.cgc = data.cgc;
    this.name = data.name;
    this.email = data.email;
    this.password = data.password;
    this.beneficiaries = data.beneficiaries;
  }
}
