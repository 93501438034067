import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/services/config';
import { redirectIfLoggedIn } from 'src/app/core/store/actions';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectTenantNome } from 'src/app/core/store/selectors';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy, AfterViewInit {
  destroyed$ = new Subject<void>();

  tenantNome?: string;

  constructor(private store: Store<IAppState>, private config: ConfigService) {}
  ngAfterViewInit(): void {}

  get shouldShowSubtitle(): boolean {
    return this.config.getConfiguration('AUTH_SHOULD_SHOW_SUBTITLE');
  }

  get shouldShowButtons(): boolean {
    return this.config.getConfiguration('AUTH_SHOULD_SHOW_BUTTONS');
  }

  ngOnInit(): void {
    this.store.dispatch(redirectIfLoggedIn());
    this.setTenantNome();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setTenantNome(): void {
    this.store
      .select(selectTenantNome)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tenantNome) => {
        this.tenantNome = tenantNome;
      });
  }
}
