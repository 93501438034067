<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Extrato de coparticipação', '']
  ]"
></app-breadcrumb>

<mat-card class="mt-4">
  <h4>Escolha o beneficiário para buscar os dados de coparticipação</h4>

  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.name }}</td>
    </ng-container>

    <ng-container matColumnDef="birthDate">
      <th mat-header-cell *matHeaderCellDef>Data de Nascimento</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.birthDate | dashToBar }}</td>
    </ng-container>

    <ng-container matColumnDef="kinship">
      <th mat-header-cell *matHeaderCellDef>Parentesco</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.kinship }}</td>
    </ng-container>

    <ng-container matColumnDef="cardNumber">
      <th mat-header-cell *matHeaderCellDef>Carteirinha</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.cardNumber }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let beneficiary; columns: displayedColumns"
      class="c-pointer"
      (click)="selectBeneficiary(beneficiary)"
    ></tr>
  </table>
</mat-card>

<app-modal [control]="modalControl" size="md">
  <app-modal-busca-extrato-copart
    *ngIf="modalControl.isOpened"
    (finished)="searchCoparticipation($event)"
    (canceled)="modalControl.close()"
  ></app-modal-busca-extrato-copart>
</app-modal>
