<app-breadcrumb [steps]="(userType$ | async) === 'PJ' ? PJBreadcrumb : PFBreadcrum"></app-breadcrumb>

<mat-card class="mt-4">
  <h4>Cancelamento RN561</h4>
  <p class="text-muted">Selecione o titular e/ou o(s) dependente(s).</p>

  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>Selecione</th>
      <td mat-cell *matCellDef="let beneficiary; let i = index">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event && toggleSelection(beneficiary, i)"
          [checked]="selection.isSelected(beneficiary)"
          *ngIf="beneficiary.status === 'Ativo'"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.name }}</td>
    </ng-container>

    <ng-container matColumnDef="cpf">
      <th mat-header-cell *matHeaderCellDef>CPF</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.cpf | mask: '000.000.000-00' }}</td>
    </ng-container>

    <ng-container matColumnDef="kinship">
      <th mat-header-cell *matHeaderCellDef>Parentesco</th>
      <td mat-cell *matCellDef="let beneficiary">{{ beneficiary.kinship }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let beneficiary; columns: displayedColumns; let i = index"
      [class.c-pointer]="beneficiary.status === 'Ativo'"
      (click)="toggleSelection(beneficiary, i)"
    ></tr>
  </table>

  <div class="mt-3">
    <button mat-raised-button color="accent" [disabled]="selection.isEmpty()" (click)="onSubmit()">
      Cancelar selecionados
    </button>
    <button mat-stroked-button color="secondary" class="ms-2" [routerLink]="backUrl$ | async">Voltar</button>
  </div>
</mat-card>

<app-modal [control]="modalControl">
  <app-modal-cancelamento-imediato
    *ngIf="modalControl.isOpened"
    (finished)="onModalClosed($event)"
    (canceled)="modalControl.close()"
  ></app-modal-cancelamento-imediato>
</app-modal>
