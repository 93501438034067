interface Month {
  value: string;
  description: string;
}

export default [
  { value: '01', description: 'Janeiro' },
  { value: '02', description: 'Fevereiro' },
  { value: '03', description: 'Março' },
  { value: '04', description: 'Abril' },
  { value: '05', description: 'Maio' },
  { value: '06', description: 'Junho' },
  { value: '07', description: 'Julho' },
  { value: '08', description: 'Agosto' },
  { value: '09', description: 'Setembro' },
  { value: '10', description: 'Outubro' },
  { value: '11', description: 'Novembro' },
  { value: '12', description: 'Dezembro' },
] as Month[];
