<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Extrato de cobrança', 'extrato/cobranca/busca'],
    ['Resultado', '']
  ]"
></app-breadcrumb>

<mat-card class="mt-4">
  <div class="d-flex justify-content-between">
    <h4 class="mb-4">Extrato de cobrança</h4>
    <button mat-raised-button color="primary" (click)="print()" *ngIf="invoice">
      <mat-icon>print</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="invoice">
    <div *ngIf="invoice.dischargeDate">
      <b>Data de quitação</b>
      : {{ invoice.dischargeDate }}
    </div>
    <div *ngIf="invoice.dueDate">
      <b>Data de vencimento</b>
      : {{ invoice.dueDate | protheusDate }}
    </div>
    <div *ngIf="invoice.yearMonth">
      <b>Mês/Ano</b>
      : {{ invoice.yearMonth | protheusDate }}
    </div>
    <div *ngIf="invoice.amount">
      <b>Valor</b>
      : {{ invoice.amount | currency: 'BRL' }}
    </div>
  </ng-container>

  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="debitCredit">
      <th mat-header-cell *matHeaderCellDef>Débito/Crédito</th>
      <td mat-cell *matCellDef="let detail">{{ detail.debitCredit }}</td>
    </ng-container>

    <ng-container matColumnDef="userType">
      <th mat-header-cell *matHeaderCellDef>Tipo do beneficiário</th>
      <td mat-cell *matCellDef="let detail">{{ detail.userType }}</td>
    </ng-container>

    <ng-container matColumnDef="event">
      <th mat-header-cell *matHeaderCellDef>Evento</th>
      <td mat-cell *matCellDef="let detail">{{ detail.event }}</td>
    </ng-container>

    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef>Nome do beneficiário</th>
      <td mat-cell *matCellDef="let detail">{{ detail.userName }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Valor</th>
      <td mat-cell *matCellDef="let detail">{{ detail.amount | currency: 'BRL' }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Descrição</th>
      <td mat-cell *matCellDef="let detail">{{ detail.description }}</td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" colspan="9999" *ngIf="loaded">Nenhum dado encontrado</td>
    </tr>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let detail; columns: displayedColumns"></tr>
  </table>
</mat-card>
