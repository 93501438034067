import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { NgxMaskModule } from 'ngx-mask';

import { PagamentosEDebitosRoutingModule } from './pagamentos-e-debitos-routing.module';
import { DeclaracaoAnualDeDebitoComponent } from './components/declaracao-anual-de-debito';
import { InformeDePagamentoComponent } from './components/informe-de-pagamento';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { CartaDeQuitacaoComponent } from './components/carta-de-quitacao';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [DeclaracaoAnualDeDebitoComponent, InformeDePagamentoComponent, CartaDeQuitacaoComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagamentosEDebitosRoutingModule,
    ComponentsModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,

    NgxMaskModule.forRoot(),

    PipesModule,
  ],
})
export class PagamentosEDebitosModule {}
