import { getExtensionFromFilename } from 'src/app/core/utils/getExtensionFromFilename';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { isImageExtension } from 'src/app/core/utils/isImageExtension';
import { BeneficiaryDocument } from '../../../models/BeneficiaryDocument';

declare let pdfjsLib: any;

@Component({
  selector: 'app-beneficiary-document-input',
  templateUrl: './beneficiary-document-input.component.html',
  styleUrls: ['./beneficiary-document-input.component.scss'],
})
export class BeneficiaryDocumentInputComponent {
  @ViewChild('imgPreview') imgPreview!: ElementRef;
  @ViewChild('pdfPreview') pdfPreview!: ElementRef;
  @ViewChild('pdfPreviewContainer') pdfPreviewContainer!: ElementRef;
  @ViewChild('inputFile') inputFile!: ElementRef;

  @Input() document!: BeneficiaryDocument;

  @Input() hasValue!: boolean;

  @Input() sent!: boolean;

  @Output() changed = new EventEmitter<File>();

  @Output() removed = new EventEmitter<File>();

  touched = false;

  faFileUpload = faFileUpload;

  fileName?: string;

  get shouldShowRequiredBorder(): boolean {
    return this.document.obrigatorio && !this.hasValue && (this.sent || this.touched);
  }

  get shouldShowInsertedBorder(): boolean {
    return this.hasValue;
  }

  get isImage(): boolean {
    return isImageExtension(this.fileExtension ?? '');
  }

  get isPdf(): boolean {
    return (this.fileName ?? '').toLowerCase().endsWith('pdf');
  }

  get fileExtension(): string {
    return getExtensionFromFilename(this.fileName ?? '');
  }

  onInput(event: Event): void {
    const file = this.extractFileFromEvent(event);

    if (!file) {
      return;
    }

    this.changed.emit(file);
    this.touched = true;
    this.fileName = file.name;

    const canvas = this.pdfPreview.nativeElement as any;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);

    if (this.isImage) {
      this.showImgPreview(file);
    }

    if (this.isPdf) {
      this.showPdfPreview(file);
    }

    if (event.target) {
      (event.target as HTMLInputElement).value = '';
    }
  }

  remove(event: Event): void {
    event.stopImmediatePropagation();

    this.removed.emit();
  }

  onContainerClick(): void {
    if (this.shouldShowInsertedBorder) {
      return;
    }
    this.inputFile.nativeElement.click();
  }

  private extractFileFromEvent(event: Event): File | null {
    const target = event?.target as any;

    return target?.files?.[0] as File;
  }

  private showPdfPreview(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const canvas = this.pdfPreview.nativeElement as any;
      const container = this.pdfPreviewContainer.nativeElement as any;

      pdfjsLib
        .getDocument(reader.result)
        .promise.then((pdf: any) => pdf.getPage(1))
        .then((page: any) => {
          const scale = container.clientWidth / page.getViewport(1).width;

          page.render({
            canvasContext: canvas.getContext('2d'),
            viewport: page.getViewport(scale),
          });
        });
    };
  }

  private showImgPreview(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imgEl = this.imgPreview.nativeElement as any;
      imgEl.src = reader.result;
    };
  }
}
