import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { DpsQuestion } from '../../models/DpsQuestions';

@Injectable()
export class DpsQuestionsService {
  constructor(private http: HttpClient) {}

  getAll(): Observable<DpsQuestion[]> {
    const baseURL = endpoints.dpsQuestions;

    return this.http.get<DpsQuestion[]>(baseURL);
  }
}
